import React from "react";
import { Chart } from "react-google-charts";

interface HistogramProps {
  data: any;
}

export const HistogramGoogle: React.FC<HistogramProps> = ({ data }) => {
  return (
    <Chart
      width="100%"
      height="100%"
      chartType="Histogram"
      data={data}
      options={{
        legend: { position: "none" },
      }}
    />
  );
};
