import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface TableMUIProps {
  rows: any;
  columns: GridColDef[];
}

export const TableMUI: React.FC<TableMUIProps> = ({ rows, columns }) => {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      pageSizeOptions={[5]}
      checkboxSelection
      disableRowSelectionOnClick
    />
  );
};
