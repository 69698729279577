import React from "react";
import { Chart } from "react-google-charts";

interface ColumnChartProps {
  data: any;
}

export const ColumnChartGoogle: React.FC<ColumnChartProps> = ({ data }) => {
  return (
    <Chart width="100%" height="100%" chartType="ColumnChart" data={data} />
  );
};
