import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { OllamaRequest } from "../dtos/OllamaRequest.dto";
import { OLLAMA_BASE_URL } from "./constant/const";

export const ollamaApi = createApi({
  reducerPath: "ollamaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: OLLAMA_BASE_URL,
  }),
  endpoints: (build) => ({
    generateResponse: build.mutation<any, string>({
      query: (prompt) => ({
        url: "/generate",
        method: "POST",
        body: {
          prompt,
          model: "deepseek-coder",
          stream: false,
        } as OllamaRequest,
      }),
    }),
  }),
});

export const { useGenerateResponseMutation } = ollamaApi;
