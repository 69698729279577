import React, { useEffect, useState } from "react";
import "./Home.css";
import {
  DollarSign,
  Package,
  ShoppingCart,
  Star,
  TrendingUp,
} from "lucide-react";
import { SearchBar } from "../../components/searchbars/SearchBar/SearchBar";
import { CategoryBox } from "../../components/boxes/CategoryBox/CategoryBox";
import { FeaturedKpiBox } from "../../components/boxes/FeaturedKpiBox/FeaturedKpiBox";
import { Loading } from "../Loading";
import {
  financialHealthSeparate,
  operationalEfficiencySeparate,
  salesRevenueSeparate,
  supplyChainSeparate,
} from "../../utils/main/separateCategories";
import { icons } from "../../utils/main/icons";
import { getColor } from "../../utils/main/generateColor";
import { useDnasMutation } from "../../api/ios.api";
import { DialogPopup } from "../../components/dialogs/DialogPopup/DialogPopup";
import { dialogContent } from "../../constants/dialogContent";
import Toast from "../../components/toasts/Toast/Toast";

export const Home: React.FC = () => {
  const [dnas, setDnas] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredDnas, setFilteredDnas] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDialogContent, setSelectedDialogContent] = useState<any>(null);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<"success" | "error" | "info">(
    "info"
  );

  const [ios] = useDnasMutation();

  const handleApiError = (error: any) => {
    if (error.status === 403) {
      setToastMessage(
        "Whoops! Something went wrong. To generate this report, ensure you have access to all the required applications. Please contact your administrator."
      );
      setToastType("error");
    } else if (error.status === 404) {
      setToastMessage(
        "The requested data could not be found. Please check your inputs or contact support."
      );
      setToastType("error");
    } else {
      setToastMessage("An error occurred. Please try again later.");
      setToastType("error");
    }
  };

  useEffect(() => {
    const fetchKpis = async () => {
      try {
        const response = await ios();
        if ("data" in response) {
          const data = response.data || [];
          setDnas(data);
          setFilteredDnas(data);
        } else if (response.error) {
          handleApiError(response.error);
        }
      } catch (err) {
        console.error("Unexpected error:", err);
        setToastMessage(
          "An unexpected error occurred. Please try again later."
        );
        setToastType("error");
      } finally {
        setLoading(false);
      }
    };

    fetchKpis();
  }, [ios]);

  useEffect(() => {
    const filtered = dnas?.filter((dna) => {
      const searchTerm = searchValue.toLowerCase();
      return (
        dna.name?.toLowerCase().includes(searchTerm) ||
        dna.type?.toLowerCase().includes(searchTerm) ||
        dna.category?.toLowerCase().includes(searchTerm) ||
        dna.description?.toLowerCase().includes(searchTerm) ||
        dna.visualization?.toLowerCase().includes(searchTerm)
      );
    });
    setFilteredDnas(filtered || []);
  }, [searchValue, dnas]);

  if (loading) {
    return <Loading />;
  }

  const toggleDialog = (name?: string) => {
    if (name) {
      const content = dialogContent.find((item) => item.name === name);
      setSelectedDialogContent(content || null);
    }
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <div className="Page">
      <div className="Header-Home">
        <h1>KPI Analytics Hub</h1>
        <SearchBar
          placeholder="Search KPIs by name, description, or category..."
          onSearch={setSearchValue}
          results={filteredDnas?.map((dna) => ({
            id: dna.id,
            name: dna.name,
            type: dna.type,
            category: dna.category,
            description: dna.description,
            visualization: dna.visualization,
          }))}
        />
      </div>

      <div className="Kpis">
        <div className="FeaturedKPIs">
          <div className="FeaturedKPIsTitle">
            <Star size={24} color="#F3E2AA" style={{ cursor: "pointer" }} />
            <h2>Featured KPIs</h2>
          </div>
          {dnas?.length !== 0 &&
            dnas
              .slice(0, 3)
              .map((dna, index) => (
                <FeaturedKpiBox
                  key={index}
                  icon={icons[index % icons.length]}
                  title={dna?.name}
                  description={dna?.description}
                  type={dna?.type}
                  visualization={dna?.visualization}
                  factors={dna?.external_factors}
                  color={getColor(index).color}
                  border={getColor(index).border}
                  link={`/dna/${dna?.name}`}
                  onClick={() => toggleDialog(dna?.name)}
                />
              ))}
        </div>

        <CategoryBox
          title="Financial Health"
          icon={<DollarSign size={20} color="#16A34A" />}
          kpis={financialHealthSeparate(dnas)}
          onClick={(name) => toggleDialog(name)}
        />
        <CategoryBox
          title="Operational Efficiency"
          icon={<TrendingUp size={20} color="#2563EB" />}
          kpis={operationalEfficiencySeparate(dnas)}
          onClick={(name) => toggleDialog(name)}
        />
        <CategoryBox
          title="Supply Chain"
          icon={<Package size={20} color="#A04BEC" />}
          kpis={supplyChainSeparate(dnas)}
          onClick={(name) => toggleDialog(name)}
        />
        <CategoryBox
          title="Sales & Revenue"
          icon={<ShoppingCart size={20} color="#EA5F17" />}
          kpis={salesRevenueSeparate(dnas)}
          onClick={(name) => toggleDialog(name)}
        />
      </div>
      <DialogPopup
        isOpen={isDialogOpen}
        onClose={toggleDialog}
        data={selectedDialogContent}
      />
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          onClose={() => setToastMessage("")}
        />
      )}
    </div>
  );
};
