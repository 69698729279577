import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { clearAuth } from "../slices/auth.slice";
import styles from "./ProtectedLayout.module.css";
import { removeToken } from "../utils/token";
import { removeUrl } from "../utils/url";
import { ArrowLeft } from "lucide-react";

export const ProtectedLayout: React.FC = () => {
  const user = useAppSelector((state) => state.auth.username);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = () => {
    dispatch(clearAuth());
    setIsDropdownOpen(false);
    removeToken();
    removeUrl();
  };

  if (!user) return <Navigate to={ROUTES.login} />;

  const headerStyle =
    location.pathname !== ROUTES.home
      ? { justifyContent: "space-between" }
      : { justifyContent: "flex-end" };

  return (
    <div className={styles.layout}>
      <header className={styles.header} style={headerStyle}>
        {location.pathname !== ROUTES.home && (
          <Link to={ROUTES.home} className="Link">
            <ArrowLeft size={18} /> Back
          </Link>
        )}
        <div
          className={styles.username}
          onClick={() => setIsDropdownOpen((prev) => !prev)}
        >
          {user}
          {isDropdownOpen && (
            <div className={styles.dropdown}>
              <button className={styles.logoutButton} onClick={handleLogout}>
                Logout
              </button>
            </div>
          )}
        </div>
      </header>
      <main className={styles.main}>
        <Outlet />
      </main>
    </div>
  );
};
