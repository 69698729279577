import React from "react";
import { Treemap, ResponsiveContainer } from "recharts";

interface TreeMapRechartsProps {
  data: any;
}

export const TreeMapRecharts: React.FC<TreeMapRechartsProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <Treemap
        width={400}
        height={200}
        data={data}
        dataKey="size"
        aspectRatio={4 / 3}
        stroke="#fff"
        fill="#8884d8"
      />
    </ResponsiveContainer>
  );
};
