export const geographicMapFilter = (
  data: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  const result: Record<string, number> = {};

  data.forEach((item: any) => {
    const indexValue = item[selectedIndex];
    const values = selectedKeys.reduce((sum, key) => sum + (item[key] || 0), 0);

    if (result[indexValue]) {
      result[indexValue] += values;
    } else {
      result[indexValue] = values;
    }
  });

  const response = [[selectedIndex, "Value"]];
  Object.entries(result).forEach(([key, value]) => {
    response.push([key, value.toString()]);
  });

  return response;
};

export const dynamicHeatmapFilter = (
  data: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  const result: any[] = [];

  data.forEach((item: any) => {
    const indexValue = item[selectedIndex];

    const itemData = {
      id: indexValue,
      data: [] as any,
    };

    selectedKeys.forEach((key) => {
      const value = item[key] || 0;
      itemData.data.push({
        x: key,
        y: value,
      });
    });

    result.push(itemData);
  });

  return result;
};

export const processDataForTreemap = (
  data: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  const tree: any = [];
  const [primaryKey, ...remainingKeys] = selectedKeys;

  const segments = data.reduce((acc, item) => {
    const mainSegment = item[selectedIndex];
    const subSegment = item[primaryKey];
    const size = remainingKeys.reduce((sum, key) => sum + (item[key] || 0), 0);

    let mainNode = acc.find((node: any) => node.name === mainSegment);
    if (!mainNode) {
      mainNode = { name: mainSegment, children: [] };
      acc.push(mainNode);
    }

    let subNode = mainNode.children?.find(
      (node: any) => node.name === subSegment
    );
    if (!subNode) {
      subNode = { name: subSegment, size };
      mainNode.children?.push(subNode);
    } else {
      subNode.size += size;
    }

    return acc;
  }, tree);

  return segments;
};

export const processDataToRowsGantt = (
  data: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  return data.map((item: any, index: number) => {
    let startDate, endDate;

    if (item[selectedIndex]?.split) {
      const [month, year] = item[selectedIndex].split("/");
      const dateValue = new Date(parseInt(year), parseInt(month) - 1);
      startDate = new Date(dateValue.getFullYear(), dateValue.getMonth(), 1);
      endDate = new Date(dateValue.getFullYear(), dateValue.getMonth(), 25);
    } else if (item.lead_time_days) {
      startDate = new Date();
      endDate = new Date(
        startDate.getTime() + item.lead_time_days * 24 * 60 * 60 * 1000
      );
    } else {
      startDate = new Date();
      endDate = new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000);
    }

    const effectiveValue = selectedKeys.reduce(
      (sum, key) => sum + (item[key] || 0),
      0
    );

    const taskId = `Task${index + 1}`;
    const taskName = item[selectedIndex];

    return [
      taskId,
      taskName,
      `Resource ${index + 1}`,
      startDate,
      endDate,
      null,
      effectiveValue,
      null,
    ];
  });
};

export const transformDataForHistogram = (
  responseData: any,
  selectedIndex: string,
  selectedKeys: string[]
) => {
  const data = [[selectedIndex, ...selectedKeys]];

  responseData.forEach((item: any) => {
    const label = item[selectedIndex];
    const values = selectedKeys.map((key) => item[key] || 0);
    data.push([label, ...values]);
  });

  return data;
};

export const transformDataForPieChart = (
  data: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  return data?.map((item) => ({
    id: item[selectedIndex],
    value: selectedKeys?.reduce((sum, key) => sum + (item[key] || 0), 0),
  }));
};

export const transformDataForGauge = (
  responseData: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  if (!responseData?.length || !selectedKeys?.length) {
    return [
      ["Label", "Value"],
      ["No Data", 0],
    ];
  }

  const latestPoint = responseData[responseData.length - 1];
  const label = latestPoint[selectedIndex] || "Unknown";
  const value = Number(latestPoint[selectedKeys[0]]) || 0;

  return [
    ["Label", "Value"],
    [label, value],
  ];
};

export const transformDataForTableGoogle = (
  responseData: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  if (!responseData?.length || !selectedKeys?.length) {
    return [["No Data", "No Values"]];
  }

  const headers = [selectedIndex, ...selectedKeys];
  const rows = responseData.map((item) => {
    const indexValue = item[selectedIndex];
    const values = selectedKeys.map((key) => item[key] || 0);
    return [indexValue, ...values];
  });

  return [headers, ...rows];
};

export const transformDataForTable = (
  data: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  if (!data?.length) {
    return { columns: [], rows: [] };
  }

  const columns: any = [
    { field: selectedIndex, headerName: selectedIndex, width: 150 },
    ...selectedKeys.map((key) => ({
      field: key,
      headerName: key,
      width: 150,
      type: typeof data[0][key] === "number" ? "number" : "string",
      editable: false,
    })),
  ];

  const rows = data.map((item, index) => ({
    id: index,
    [selectedIndex]: item[selectedIndex],
    ...selectedKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: item[key],
      }),
      {}
    ),
  }));

  return { columns, rows };
};

export const transformDataForFunnel = (
  data: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  if (!data?.length || !selectedIndex || !selectedKeys?.length) {
    return [
      {
        id: "unknown",
        value: 0,
        label: "No Data",
      },
    ];
  }

  return data.map((item) => ({
    id: item?.[selectedIndex] ?? "unknown",
    value: selectedKeys?.reduce((sum, key) => sum + (item?.[key] || 0), 0),
    label: item?.[selectedIndex]
      ? `Stage ${item[selectedIndex]}`
      : "Unknown Stage",
  }));
};

export const transformDataForSankey = (
  data: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  if (!data?.length || !selectedIndex || !selectedKeys?.length) {
    return {
      nodes: [{ id: "No Data", nodeColor: "hsl(0, 0%, 50%)" }],
      links: [],
    };
  }

  const nodes = data.map((item, index) => ({
    id: `Stage ${item[selectedIndex] || index + 1}`,
    nodeColor: `hsl(${(index * 60) % 360}, 70%, 50%)`,
  }));

  const links = data.slice(0, -1).map((sourceItem, index) => {
    const targetItem = data[index + 1];
    const sourceName = `Stage ${sourceItem[selectedIndex] || index + 1}`;
    const targetName = `Stage ${targetItem[selectedIndex] || index + 2}`;

    const value = selectedKeys.reduce(
      (sum, key) => sum + (sourceItem[key] || 0),
      0
    );

    return {
      source: sourceName,
      target: targetName,
      value: value || 1,
    };
  });

  return {
    nodes,
    links,
  };
};

export const transformDataForScatter = (
  data: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  if (!data?.length || !selectedIndex || !selectedKeys.length) {
    return [
      {
        id: "No Data",
        data: [{ x: 0, y: 0 }],
      },
    ];
  }

  const groupedData = data.reduce((acc: any, item) => {
    const groupKey = item[selectedIndex] || "Unknown";

    if (!acc[groupKey]) {
      acc[groupKey] = {
        id: groupKey,
        data: [],
      };
    }

    acc[groupKey].data.push({
      x: item.amount_total || 0,
      y: item.duplicate_count || 0,
    });

    return acc;
  }, {});

  return Object.values(groupedData);
};

export const transformDataForWaterfall = (
  responseData: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  if (!responseData?.length || !selectedKeys?.length) {
    return [
      ["Period", "", "", "", ""],
      ["No Data", 0, 0, 0, 0],
    ];
  }

  const headerRow = [selectedIndex, "", "", "", ""];

  const dataRows = responseData.map((item) => {
    const indexValue = item[selectedIndex];
    const values = selectedKeys.map((key) => Math.abs(Number(item[key])) || 0);

    while (values.length < 4) {
      values.push(0);
    }

    return [indexValue, ...values];
  });

  return [headerRow, ...dataRows];
};
