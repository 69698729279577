import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface WaterfallProps {
  data: any;
  indexBy: string;
  keys: string[];
}

export const WaterfallRecharts: React.FC<WaterfallProps> = ({
  data,
  indexBy,
  keys,
}) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={indexBy} />
        <YAxis />
        <Tooltip />
        {keys.map((key, keyIndex) => (
          <React.Fragment key={key}>
            <Bar dataKey={key} stackId={key} fill="transparent" />
            <Bar dataKey={key} stackId={key}>
              {data.map((item: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    item[key] < 0
                      ? "#B22222"
                      : item.name === "Total"
                      ? "#0000CD"
                      : `hsl(${120 + keyIndex * 40}, 70%, 50%)`
                  }
                />
              ))}
            </Bar>
          </React.Fragment>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
