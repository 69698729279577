import React from "react";
import { useAppSelector } from "../app/hooks";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "../constants/routes";

export const DefaultLayout: React.FC = () => {
  const user = useAppSelector((state) => state.auth.username);

  if (user) return <Navigate replace to={ROUTES.home} />;

  return <Outlet />;
};
