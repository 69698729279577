import { ChartBox } from "../../components/boxes/ChartBox/ChartBox";
import { AreaChartRecharts } from "../../components/charts/AreaChart/recharts/AreaChartRecharts";
import { BarChartNivo } from "../../components/charts/BarChart/nivo/BarChartNivo";
import { BarChartRecharts } from "../../components/charts/BarChart/recharts/BarChartRecharts";
import { ColumnChartGoogle } from "../../components/charts/ColumnChart/google/ColumnChartGoogle";
import { FunnelChartNivo } from "../../components/charts/FunnelChart/nivo/FunnelChartNivo";
import { GanttChartGoogle } from "../../components/charts/GanttChart/google/GanttChartGoogle";
import { GaugeChartGoogle } from "../../components/charts/GaugeChart/google/GaugeChartGoogle";
import { GeoMapGoogle } from "../../components/charts/GeographicMap/google/GeoMapGoogle";
import { HeatmapNivo } from "../../components/charts/Heatmap/nivo/HeatmapNivo";
import { HistogramGoogle } from "../../components/charts/Histogram/google/HistogramGoogle";
import { LineChartRecharts } from "../../components/charts/LineChart/recharts/LineChartRecharts";
import { PieChartNivo } from "../../components/charts/PieChart/nivo/PieChartNivo";
import { PieChartRecharts } from "../../components/charts/PieChart/recharts/PieChartRecharts";
import { RadarChartNivo } from "../../components/charts/RadarChart/nivo/RadarChartNivo";
import { RiskMatrixNivo } from "../../components/charts/RiskMatrix/nivo/RiskMatrixNivo";
import { SankeyNivo } from "../../components/charts/SankeyDiagram/nivo/SankeyNivo";
import { ScatterPlotNivo } from "../../components/charts/ScatterPlot/nivo/ScatterChartNivo";
import { StackedBarChartNivo } from "../../components/charts/StackedBarChart/nivo/StackedBarChartNivo";
import { StackedBarChartRecharts } from "../../components/charts/StackedBarChart/recharts/StackedBarChartRecharts";
import { TableGoogle } from "../../components/charts/TableChart/google/TableGoogle";
import { TableMUI } from "../../components/charts/TableChart/MUI/TableMUI";
import { TreeMapRecharts } from "../../components/charts/Treemap/recharts/TreemapRecharts";
import { WaterfallRecharts } from "../../components/charts/WaterfallChart/recharts/WaterfallRecharts";
import { colors } from "../../constants/colors";
import { validateChartData } from "../error";
import { findDataRanges } from "../findDataRanges";
import {
  generalChartDataConverter,
  groupChartData,
} from "./chartDataConverter";
import { generateCumulativeData } from "./generateCumulativeData";
import {
  dynamicHeatmapFilter,
  geographicMapFilter,
  processDataForTreemap,
  processDataToRowsGantt,
  transformDataForFunnel,
  transformDataForGauge,
  transformDataForHistogram,
  transformDataForPieChart,
  transformDataForSankey,
  transformDataForScatter,
  transformDataForTable,
  transformDataForWaterfall,
} from "./processChartData";

export const renderCharts = (
  visual: string,
  chartData: any,
  selectedIndex: string,
  selectedKeys: string[]
) => {
  const error = validateChartData(chartData, selectedIndex, selectedKeys);

  if (error) {
    return (
      <div
        key="error"
        style={{
          height: "400px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          color: "#ff4d4f",
          padding: "20px",
          textAlign: "center",
          border: "1px solid #ffccc7",
          borderRadius: "4px",
          background: "#fff2f0",
        }}
      >
        {error}
      </div>
    );
  }

  const chartTypes = visual?.split(";");

  const convertedChartData = generalChartDataConverter(chartData);

  const groupedData = groupChartData(
    convertedChartData,
    selectedIndex,
    selectedKeys
  );

  return chartTypes?.map((chartType, index) => {
    switch (chartType?.trim()) {
      case "Line Chart":
        return (
          <ChartBox
            key={index}
            title="Line Chart"
            height={400}
            chart={
              <LineChartRecharts
                data={groupedData}
                xKey={selectedIndex}
                yKeys={selectedKeys}
                colors={colors}
              />
            }
          />
        );
      case "Bar Chart":
        return (
          <ChartBox
            key={index}
            title="Bar Chart"
            height={400}
            chart={
              <BarChartNivo
                data={groupedData}
                indexBy={selectedIndex}
                keys={selectedKeys}
              />
            }
          />
        );
      case "Pie Chart":
        return (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(600px, 1fr))",
              gap: "20px",
            }}
            key={index}
          >
            {selectedKeys.map((key, i) => {
              const chartData = transformDataForPieChart(
                groupedData,
                selectedIndex,
                [key]
              );

              const hasNonZeroValues = chartData.some(
                (item) => item.value !== 0
              );

              return hasNonZeroValues ? (
                <ChartBox
                  key={`pie-${i}`}
                  title={`Pie Chart - ${key}`}
                  chart={<PieChartNivo data={chartData} />}
                />
              ) : (
                <ChartBox
                  key={`pie-${i}`}
                  title={`Pie Chart - ${key}`}
                  chart={
                    <div
                      style={{
                        height: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#666",
                      }}
                    >
                      No {key} to display
                    </div>
                  }
                />
              );
            })}
          </div>
        );
      case "Gantt Chart":
        return (
          <div
            key={index}
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(600px, 1fr))",
              gap: "20px",
            }}
          >
            {selectedKeys.map((key, i) => {
              const chartData = processDataToRowsGantt(
                groupedData,
                selectedIndex,
                [key]
              );

              const hasNonZeroValues = chartData.some(
                (item) => item[i][6] !== 0
              );

              return hasNonZeroValues ? (
                <ChartBox
                  key={`gantt-${i}`}
                  title={`Gantt Chart - ${key}`}
                  height={groupedData.length * 44}
                  chart={<GanttChartGoogle rows={chartData} />}
                />
              ) : (
                <ChartBox
                  key={`gantt-${i}`}
                  title={`Gantt Chart - ${key}`}
                  chart={
                    <div
                      style={{
                        height: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#666",
                      }}
                    >
                      No {key} to display
                    </div>
                  }
                />
              );
            })}
          </div>
        );
      case "Histogram":
        return (
          <ChartBox
            key={index}
            title="Histogram"
            chart={
              <HistogramGoogle
                data={transformDataForHistogram(
                  groupedData,
                  selectedIndex,
                  selectedKeys
                )}
              />
            }
          />
        );
      case "Column Chart":
        return (
          <ChartBox
            key={index}
            title="Column Chart"
            chart={
              <ColumnChartGoogle
                data={transformDataForHistogram(
                  groupedData,
                  selectedIndex,
                  selectedKeys
                )}
              />
            }
          />
        );
      case "Area Chart":
        return (
          <ChartBox
            key={index}
            title="Area Chart"
            chart={
              <AreaChartRecharts
                data={groupedData}
                xKey={selectedIndex}
                yKeys={selectedKeys}
                colors={colors}
              />
            }
          />
        );
      case "Funnel Chart":
        return (
          <div
            key={index}
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(600px, 1fr))",
              gap: "20px",
            }}
          >
            {selectedKeys.map((key, i) => {
              const chartData = transformDataForFunnel(
                groupedData,
                selectedIndex,
                [key]
              );

              const hasNonZeroValues = chartData.some(
                (item) => item.value !== 0
              );

              return hasNonZeroValues ? (
                <ChartBox
                  key={`funnel-${i}`}
                  title={`Funnel Chart - ${key}`}
                  height={groupedData.length * 50}
                  chart={<FunnelChartNivo data={chartData} />}
                />
              ) : (
                <ChartBox
                  key={`funnel-${i}`}
                  title={`Funnel Chart - ${key}`}
                  chart={
                    <div
                      style={{
                        height: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#666",
                      }}
                    >
                      No {key} to display
                    </div>
                  }
                />
              );
            })}
          </div>
        );
      case "Geographic Map":
        return (
          <div
            key={index}
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(600px, 1fr))",
              gap: "20px",
            }}
          >
            {selectedKeys.map((key, i) => {
              const chartData = geographicMapFilter(
                groupedData,
                selectedIndex,
                [key]
              );

              const hasNonZeroValues = chartData.some(
                (item) => item[i][0] !== "0"
              );

              return hasNonZeroValues ? (
                <ChartBox
                  key={`geo-${i}`}
                  title={`Geographic Map - ${key}`}
                  chart={<GeoMapGoogle data={chartData} />}
                />
              ) : (
                <ChartBox
                  key={`geo-${i}`}
                  title={`Geographic Map - ${key}`}
                  chart={
                    <div
                      style={{
                        height: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#666",
                      }}
                    >
                      No {key} to display
                    </div>
                  }
                />
              );
            })}
          </div>
        );
      case "Heatmap":
        return (
          <ChartBox
            key={index}
            title="Heatmap"
            chart={
              <HeatmapNivo
                data={dynamicHeatmapFilter(
                  groupedData,
                  selectedIndex,
                  selectedKeys
                )}
                indexBy={selectedIndex}
                minValue={findDataRanges(groupedData).min}
                maxValue={findDataRanges(groupedData).max}
              />
            }
          />
        );
      case "Radar Chart":
        return (
          <ChartBox
            key={index}
            title="Radar Chart"
            chart={
              <RadarChartNivo
                data={groupedData}
                keys={selectedKeys}
                indexBy={selectedIndex}
              />
            }
          />
        );
      case "Spider Chart":
        return (
          <ChartBox
            key={index}
            title="Spider Chart"
            chart={
              <RadarChartNivo
                data={groupedData}
                keys={selectedKeys}
                indexBy={selectedIndex}
              />
            }
          />
        );
      case "Sankey Diagram":
        return (
          <ChartBox
            key={index}
            title="Sankey Diagram"
            chart={
              <SankeyNivo
                data={transformDataForSankey(
                  groupedData,
                  selectedIndex,
                  selectedKeys
                )}
              />
            }
          />
        );
      case "Scatter Plot":
        return (
          <ChartBox
            key={index}
            title="Scatter Plot"
            chart={
              <ScatterPlotNivo
                data={transformDataForScatter(
                  groupedData,
                  selectedIndex,
                  selectedKeys
                )}
              />
            }
          />
        );
      case "Time Series Chart":
        return (
          <ChartBox
            key={index}
            title="Time Series Chart"
            chart={
              <LineChartRecharts
                data={groupedData}
                xKey={selectedIndex}
                yKeys={selectedKeys}
                colors={colors}
              />
            }
          />
        );
      case "Waterfall Chart":
        return (
          <ChartBox
            key={index}
            title="Waterfall Chart"
            chart={
              <WaterfallRecharts
                data={generateCumulativeData(
                  groupedData,
                  selectedIndex,
                  selectedKeys
                )}
                indexBy={selectedIndex}
                keys={selectedKeys}
              />
            }
          />
        );
      case "Stacked Bar Chart":
        return (
          <ChartBox
            key={index}
            title="Stacked Bar Chart"
            chart={
              <StackedBarChartNivo
                data={groupedData}
                indexBy={selectedIndex}
                keys={selectedKeys}
              />
            }
          />
        );
      case "Aging Chart":
        return (
          <ChartBox
            key={index}
            title="Aging Chart"
            chart={
              <StackedBarChartNivo
                data={groupedData}
                keys={selectedKeys}
                indexBy={selectedIndex}
              />
            }
          />
        );
      case "Treemap":
        return (
          <ChartBox
            key={index}
            title="Treemap"
            chart={
              <TreeMapRecharts
                data={processDataForTreemap(
                  groupedData,
                  selectedIndex,
                  selectedKeys
                )}
              />
            }
          />
        );
      case "Gauge":
        return (
          <div
            key={index}
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(600px, 1fr))",
              gap: "20px",
            }}
          >
            {selectedKeys.map((key, i) => {
              const chartData = transformDataForGauge(
                groupedData,
                selectedIndex,
                [key]
              );

              const hasNonZeroValues = chartData.some(
                (item) => item[i][0] !== 0
              );

              return hasNonZeroValues ? (
                <ChartBox
                  key={`gauge-${i}`}
                  title={`Gauge - ${key}`}
                  chart={<GaugeChartGoogle data={chartData} />}
                />
              ) : (
                <ChartBox
                  key={`gauge-${i}`}
                  title={`Gauge - ${key}`}
                  chart={
                    <div
                      style={{
                        height: "300px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#666",
                      }}
                    >
                      No {key} to display
                    </div>
                  }
                />
              );
            })}
          </div>
        );
      case "Table Chart":
        return (
          <ChartBox
            key={index}
            title="Table Chart"
            chart={
              <TableMUI
                rows={
                  transformDataForTable(
                    groupedData,
                    selectedIndex,
                    selectedKeys
                  ).rows
                }
                columns={
                  transformDataForTable(
                    groupedData,
                    selectedIndex,
                    selectedKeys
                  ).columns
                }
              />
            }
          />
        );
      case "Risk Matrix":
        return (
          <ChartBox
            key={index}
            title="Risk Matrix"
            chart={
              <RiskMatrixNivo
                data={dynamicHeatmapFilter(
                  groupedData,
                  selectedIndex,
                  selectedKeys
                )}
                indexBy={selectedIndex}
              />
            }
          />
        );
      default:
        return null;
    }
  });
};
