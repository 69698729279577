import React from "react";
import { ResponsiveHeatMap } from "@nivo/heatmap";

interface HeatmapNivoProps {
  data: {
    id: string;
    data: {
      x: string;
      y: number;
    }[];
  }[];
  indexBy?: string;
  minValue?: number;
  maxValue?: number;
}

export const HeatmapNivo: React.FC<HeatmapNivoProps> = ({
  data,
  indexBy = "country",
  minValue = -100,
  maxValue = 100,
}) => {
  return (
    <ResponsiveHeatMap
      data={data}
      margin={{ top: 90, right: 90, bottom: 60, left: 90 }}
      valueFormat="0>-.2f"
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -90,
        legend: "",
        legendOffset: 46,
        truncateTickAt: 0,
      }}
      axisRight={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: indexBy,
        legendPosition: "middle",
        legendOffset: 70,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: indexBy,
        legendPosition: "middle",
        legendOffset: -72,
        truncateTickAt: 0,
      }}
      colors={{
        type: "diverging",
        scheme: "red_yellow_blue",
        divergeAt: 0.5,
        minValue: minValue,
        maxValue: maxValue,
      }}
      emptyColor="#555555"
      legends={[
        {
          anchor: "bottom",
          translateX: 0,
          translateY: 30,
          length: 400,
          thickness: 8,
          direction: "row",
          tickPosition: "after",
          tickSize: 3,
          tickSpacing: 4,
          tickOverlap: false,
          tickFormat: ">-.2s",
          title: "Value →",
          titleAlign: "start",
          titleOffset: 4,
        },
      ]}
    />
  );
};
