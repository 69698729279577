import React from "react";
import styles from "./DataBox.module.css";
import { Loader, MessageSquare, Send } from "lucide-react";
import { SearchBarBetween } from "../../searchbars/SearchBarBetween/SearchBarBetween";
import { ButtonIcon } from "../../buttons/ButtonIcon/ButtonIcon";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Pie } from "recharts";
import { colors } from "../../../constants/colors";

interface DataBoxProps {
  onClick?: () => void;
  inputText: string;
  setInputText: (value: string) => void;
  data?: any;
  buttonDisabled?: boolean;
}

export const DataBox: React.FC<DataBoxProps> = ({
  onClick,
  inputText,
  setInputText,
  data,
  buttonDisabled = false,
}) => {
  const parseData = () => {
    if (!data) return null;
    try {
      const cleanData =
        typeof data === "string"
          ? data.replace(/^```json\n|\n```$/g, "").trim()
          : data;

      const parsedData =
        typeof cleanData === "string" ? JSON.parse(cleanData) : cleanData;

      return parsedData;
    } catch (error) {
      console.error("Error parsing data:", error);
      return null;
    }
  };

  const parsedData = parseData();

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <MessageSquare size={20} />
        <h3>Data Intelligence Assistant</h3>
      </div>
      <div className={styles.searchBarSpace}>
        <SearchBarBetween
          placeholder="Ask about stockout patterns, trends, or request business advice..."
          inputText={inputText}
          setInputText={setInputText}
        />
        <ButtonIcon
          icon={
            !buttonDisabled ? (
              <Send size={16} />
            ) : (
              <Loader size={16} className="loader-animate" />
            )
          }
          onClick={onClick}
          disabled={buttonDisabled}
        >
          Ask
        </ButtonIcon>
      </div>
      <div className={styles.contentContainer}>
        {buttonDisabled ? (
          <div className={styles.loading}>
            <Loader size={64} className="loader-animate" />
          </div>
        ) : (
          parsedData && (
            <>
              <div className={styles.summary}>
                <h4>Summary</h4>
                <p>{parsedData?.summary}</p>
                <h4>Key Findings</h4>
                <ul>
                  {parsedData?.key_findings?.map(
                    (finding: string, index: number) => (
                      <li key={index}>{finding}</li>
                    )
                  )}
                </ul>
              </div>

              <div className={styles.visualizations}>
                {parsedData?.data_visualizations?.map(
                  (viz: any, index: number) => (
                    <div key={index} className={styles.chart}>
                      <h4>{viz.name}</h4>
                      {viz?.type?.includes("Pie") && (
                        <ResponsiveContainer width="100%" height={300}>
                          <PieChart>
                            <Pie
                              data={viz?.data}
                              dataKey="value"
                              nameKey="category"
                              cx="50%"
                              cy="50%"
                              fill="#8884d8"
                            >
                              {viz?.data?.map((entry: any, index: number) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={colors[index % colors.length]}
                                />
                              ))}
                            </Pie>
                            <Tooltip />
                            <Legend />
                          </PieChart>
                        </ResponsiveContainer>
                      )}
                      {viz?.type?.includes("Line") && (
                        <ResponsiveContainer width="100%" height={300}>
                          <LineChart data={viz?.data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="value"
                              stroke="#8884d8"
                            />
                            <Line
                              type="monotone"
                              dataKey="rate"
                              stroke="#82ca9d"
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      )}
                      {viz?.type?.includes("Bar") && (
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart data={viz?.data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="category" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="current" fill="#8884d8" />
                            <Bar dataKey="previous" fill="#82ca9d" />
                          </BarChart>
                        </ResponsiveContainer>
                      )}
                    </div>
                  )
                )}
              </div>

              <div className={styles.analysis}>
                <h4>Detailed Analysis</h4>
                <p>{parsedData?.detailed_analysis?.description}</p>
                <h4>Recommendations</h4>
                <ul>
                  {parsedData?.detailed_analysis?.recommendations?.map(
                    (rec: string, index: number) => (
                      <li key={index}>{rec}</li>
                    )
                  )}
                </ul>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};
