import React, { useState } from "react";
import styles from "./CategoryBox.module.css";
import { KpiBox, KpiBoxProps } from "../KpiBox/KpiBox";
import { ChevronDown, ChevronUp } from "lucide-react";

interface Props {
  icon: React.ReactNode;
  title: string;
  kpis: KpiBoxProps[];
  onClick?: (name?: string) => void;
}

export const CategoryBox: React.FC<Props> = ({
  icon,
  title,
  kpis,
  onClick,
}) => {
  const [showAll, setShowAll] = useState(false);
  const visibleKpis = showAll ? kpis : kpis.slice(0, 5);

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        {icon}
        <h3>{title}</h3>
      </div>
      {visibleKpis.map((kpi, index) => (
        <KpiBox key={index} {...kpi} onClick={() => onClick?.(kpi.title)} />
      ))}
      {kpis.length > 5 && (
        <button
          className={styles.toggleButton}
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? (
            <>
              Show Less <ChevronUp size={20} />
            </>
          ) : (
            <>
              Show More <ChevronDown size={20} />
            </>
          )}
        </button>
      )}
    </div>
  );
};
