import React from "react";
import { Chart } from "react-google-charts";

interface GeoMapProps {
  data: any;
}

export const GeoMapGoogle: React.FC<GeoMapProps> = ({ data }) => {
  return <Chart chartType="GeoChart" width="100%" height="100%" data={data} />;
};
