export const generateCumulativeData = (
  data: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  if (!data?.length || !selectedKeys?.length) return [];

  const cumulatives: { [key: string]: number } = {};
  selectedKeys.forEach((key) => (cumulatives[key] = 0));

  return data.map((item, index) => {
    const result: any = {
      name: item[selectedIndex] || `Item ${index + 1}`,
    };

    selectedKeys.forEach((key) => {
      const value = Number(item[key]) || 0;
      result[key] = cumulatives[key];
      result[key] = value;
      cumulatives[key] += value;
    });

    if (index === data.length - 1) {
      result.name = "Total";
    }

    return result;
  });
};
