// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toast_toast__stjyJ {
  height: auto;
  width: 300px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
}

.Toast_toast__stjyJ.Toast_success__AnxIR {
  background-color: #4caf50; /* Green */
}

.Toast_toast__stjyJ.Toast_error__i9hdX {
  background-color: #f44336; /* Red */
}

.Toast_toast__stjyJ.Toast_info__OR5px {
  background-color: #2196f3; /* Blue */
}

.Toast_closeButton__WCzZ6 {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

.Toast_closeButton__WCzZ6:hover {
  color: #ddd;
}
`, "",{"version":3,"sources":["webpack://./src/components/toasts/Toast/Toast.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,0CAA0C;EAC1C,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,oCAAoC;AACtC;;AAEA;EACE,yBAAyB,EAAE,UAAU;AACvC;;AAEA;EACE,yBAAyB,EAAE,QAAQ;AACrC;;AAEA;EACE,yBAAyB,EAAE,SAAS;AACtC;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,eAAe;EACf,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".toast {\n  height: auto;\n  width: 300px;\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  background-color: #333;\n  color: white;\n  padding: 20px;\n  border-radius: 5px;\n  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  z-index: 1000;\n  transition: opacity 0.3s ease-in-out;\n}\n\n.toast.success {\n  background-color: #4caf50; /* Green */\n}\n\n.toast.error {\n  background-color: #f44336; /* Red */\n}\n\n.toast.info {\n  background-color: #2196f3; /* Blue */\n}\n\n.closeButton {\n  position: absolute;\n  top: 8px;\n  right: 8px;\n  background: none;\n  border: none;\n  color: white;\n  font-size: 24px;\n  cursor: pointer;\n  padding: 0;\n  margin-left: 10px;\n}\n\n.closeButton:hover {\n  color: #ddd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast": `Toast_toast__stjyJ`,
	"success": `Toast_success__AnxIR`,
	"error": `Toast_error__i9hdX`,
	"info": `Toast_info__OR5px`,
	"closeButton": `Toast_closeButton__WCzZ6`
};
export default ___CSS_LOADER_EXPORT___;
