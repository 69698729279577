import React from "react";
import styles from "./DialogPopup.module.css";
import { X } from "lucide-react";
import { Table, TableProps } from "../../tables/Table/Table";
import { List } from "../../lists/List/List";

interface DialogPopupProps {
  isOpen: boolean;
  onClose?: () => void;
  data: {
    name?: string;
    sections?: {
      summary?: string;
      key_information?: {
        title: string;
        description?: string;
        list?: string[];
        table?: TableProps;
      };
      recommended_predictives?: {
        title: string;
        description?: string;
        list?: string[];
        table?: TableProps;
      };
      external_influencing_factors?: {
        title: string;
        description?: string;
        list?: string[];
        table?: TableProps;
      };
      forecasting?: {
        title: string;
        description?: string;
        list?: string[];
        table?: TableProps;
      };
      why_important?: {
        title: string;
        description?: string;
        list?: string[];
        table?: TableProps;
      };
      external_influences?: {
        title: string;
        description?: string;
        list?: string[];
        table?: TableProps;
      };
    };
  };
}

export const DialogPopup: React.FC<DialogPopupProps> = ({
  isOpen,
  onClose,
  data,
}) => {
  if (!isOpen) return null;

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
        <X size={24} className={styles.close} onClick={onClose} />
        <div className={styles.content}>
          <h3 className={styles.title}>{data?.name} Report</h3>

          {data?.sections?.summary && (
            <section id="summary" className={styles.section}>
              <h4 className={styles.sectionTitle}>Summary</h4>
              <p>{data?.sections?.summary}</p>
            </section>
          )}

          {data?.sections?.key_information && (
            <section id="key-information" className={styles.section}>
              <h4 className={styles.sectionTitle}>
                {data?.sections?.key_information?.title}
              </h4>
              <p>{data?.sections?.key_information?.description}</p>
              {data?.sections?.key_information?.list && (
                <List items={data?.sections?.key_information?.list} />
              )}
              {data?.sections?.key_information?.table && (
                <Table
                  thead={data?.sections?.key_information?.table?.thead}
                  tbody={data?.sections?.key_information?.table?.tbody}
                />
              )}
            </section>
          )}

          {data?.sections?.recommended_predictives && (
            <section id="recommended-predictives" className={styles.section}>
              <h4 className={styles.sectionTitle}>
                {data.sections.recommended_predictives.title}
              </h4>
              <p>{data?.sections?.recommended_predictives?.description}</p>
              {data.sections.recommended_predictives.list && (
                <List items={data.sections.recommended_predictives.list} />
              )}
              {data?.sections?.recommended_predictives?.table && (
                <Table
                  thead={data?.sections?.recommended_predictives?.table?.thead}
                  tbody={data?.sections?.recommended_predictives?.table?.tbody}
                />
              )}
            </section>
          )}

          {data?.sections?.external_influencing_factors && (
            <section
              id="external-influencing-factors"
              className={styles.section}
            >
              <h4 className={styles.sectionTitle}>
                {data?.sections?.external_influencing_factors?.title}
              </h4>
              <p>{data?.sections?.external_influencing_factors?.description}</p>
              <ul className="list-group">
                {data?.sections?.external_influencing_factors?.list && (
                  <List
                    items={data.sections.external_influencing_factors.list}
                  />
                )}
                {data?.sections?.external_influencing_factors?.table && (
                  <Table
                    thead={
                      data?.sections?.external_influencing_factors?.table?.thead
                    }
                    tbody={
                      data?.sections?.external_influencing_factors?.table?.tbody
                    }
                  />
                )}
              </ul>
            </section>
          )}

          {data?.sections?.forecasting && (
            <section id="forecasting" className={styles.section}>
              <h4 className={styles.sectionTitle}>
                {data.sections.forecasting.title}
              </h4>
              {data.sections.forecasting.list && (
                <List items={data.sections.forecasting.list} />
              )}
              {data?.sections?.forecasting?.table && (
                <Table
                  thead={data?.sections?.forecasting?.table?.thead}
                  tbody={data?.sections?.forecasting?.table?.tbody}
                />
              )}
            </section>
          )}

          {data?.sections?.why_important && (
            <section id="why-important" className={styles.section}>
              <h4 className={styles.sectionTitle}>
                {data.sections.why_important.title}
              </h4>
              {data.sections.why_important.list && (
                <List items={data.sections.why_important.list} />
              )}
              {data?.sections?.why_important?.table && (
                <Table
                  thead={data?.sections?.why_important?.table?.thead}
                  tbody={data?.sections?.why_important?.table?.tbody}
                />
              )}
            </section>
          )}

          {data?.sections?.external_influences && (
            <section id="external-influences" className={styles.section}>
              <h4 className={styles.sectionTitle}>
                {data.sections.external_influences.title}
              </h4>
              {data.sections.external_influences.list && (
                <List items={data.sections.external_influences.list} />
              )}
              {data?.sections?.external_influences?.table && (
                <Table
                  thead={data?.sections?.external_influences?.table?.thead}
                  tbody={data?.sections?.external_influences?.table?.tbody}
                />
              )}
            </section>
          )}
        </div>
      </div>
    </div>
  );
};
