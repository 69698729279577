import {
  Database,
  DollarSign,
  Lightbulb,
  Package,
  ShoppingCart,
  Target,
  TrendingUp,
  TriangleAlert,
} from "lucide-react";

export const financialHealth = {
  title: "Financial Health",
  icon: <DollarSign size={20} color="#16A34A" />,
  kpis: [
    {
      title: "Days Payable Outstanding (DPO)",
      description: "Track average payment cycles",
      category: "Accounts Payable",
      link: "/dna/kpi",
    },
    {
      title: "Cash Flow Forecast",
      description: "Project future cash positions",
      category: "Cash & Bank Management",
      link: "/dna/kpi",
    },
    {
      title: "Collection Effectiveness",
      description: "Monitor receivables performance",
      category: "Accounts Receivable",
      link: "/dna/kpi",
    },
  ],
};

export const operationalEfficiency = {
  title: "Operational Efficiency",
  icon: <TrendingUp size={20} color="#2563EB" />,
  kpis: [
    {
      title: "Collection Effectiveness Index",
      description: "Evaluate collection process efficiency",
      category: "Accounts Receivable",
      link: "/dna/kpi",
    },
    {
      title: "Inventory Turnover Rate",
      description: "Track inventory movement speed",
      category: "Inventory Management",
      link: "/dna/kpi",
    },
    {
      title: "Supplier Performance Analysis",
      description: "Monitor vendor reliability",
      category: "Sourcing and Procurement",
      link: "/dna/kpi",
    },
  ],
};

export const supplyChain = {
  title: "Supply Chain",
  icon: <Package size={20} color="#A04BEC" />,
  kpis: [
    {
      title: "Stock Aging Analysis",
      description: "Monitor inventory age distribution",
      category: "Inventory Management",
      link: "/dna/kpi",
    },
    {
      title: "Supplier Performance",
      description: "Track vendor reliability metrics",
      category: "Sourcing and Procurement",
      link: "/dna/kpi",
    },
    {
      title: "Purchase Order Cycle Time",
      description: "Measure procurement speed",
      category: "Sourcing and Procurement",
      link: "/dna/kpi",
    },
  ],
};

export const salesRevenue = {
  title: "Sales & Revenue",
  icon: <ShoppingCart size={20} color="#EA5F17" />,
  kpis: [
    {
      title: "Sales Growth Rate",
      description: "Track revenue trends",
      category: "Sales Management",
      link: "/dna/kpi",
    },
    {
      title: "Sales Territory Performance",
      description: "Analyze regional performance",
      category: "Sales Management",
      link: "/dna/kpi",
    },
    {
      title: "Customer Profitability",
      description: "Measure client value",
      category: "Sales Management",
      link: "/dna/kpi",
    },
  ],
};

export const qas = [
  {
    icon: <Lightbulb size={24} color="#F0C953" />,
    question: "What's causing the high stockout rate in Office Supplies?",
    answer:
      "Analysis shows three main factors: 1) Underestimated demand during lunch hours, 2) Insufficient safety stock levels, and 3) Delayed supplier deliveries. Recommend increasing safety stock by 20% and reviewing supplier agreements.",
  },
  {
    icon: <TrendingUp size={24} color="#5BD387" />,
    question:
      "How does our current stockout rate compare to industry standards?",
    answer:
      "Your current stockout rate of 4.2% is slightly above the industry average of 3.1%. However, this is primarily driven by the Office Supplies category. Other categories are performing within or better than industry standards.",
  },
];

export const dataSourceDetails = {
  icon: <Database size={20} color="#6A94F1" />,
  title: "Data Source",
  description: "Calculated using:",
  list: [
    "Inventory management system",
    "Point of sale data",
    "Order fulfillment records",
  ],
  footer: "Formula: (Stockouts / Total Inventory Demands) × 100",
};

export const keyInsightsDetails = {
  icon: <Lightbulb size={20} color="#EAD19E" />,
  title: "Key Insights",
  list: [
    "Office Supplies showing highest stockout rate (5.8%)",
    "Peak stockouts occur during lunch hours (12PM)",
    "Storage category maintains lowest stockout rate",
  ],
  listIcon: <TriangleAlert size={14} />,
  listIconColors: ["#F15B5B", "#F2D16B", "#64D68E"],
};

export const recommendedActionsDetails = {
  icon: <Target size={20} color="#E35757" />,
  title: "Recommended Actions",
  list: [
    "Increase safety stock for Office Supplies category",
    "Adjust staffing levels during peak hours (11AM-2PM)",
    "Implement automated reordering for high-risk items",
  ],
  listIcon: "numeric",
};

export const barChartData = [
  { label: "Jan", value: 10 },
  { label: "Feb", value: 20 },
  { label: "Mar", value: 30 },
  { label: "Apr", value: 40 },
  { label: "May", value: 50 },
];

export const lineChartData = [
  { date: new Date("2023-01-01"), value: 10 },
  { date: new Date("2023-02-01"), value: 20 },
  { date: new Date("2023-03-01"), value: 30 },
  { date: new Date("2023-04-01"), value: 40 },
  { date: new Date("2023-05-01"), value: 50 },
];

export const salesData = [
  {
    sale_order_id: 21,
    sale_order_name: "S00021",
    product_name: { en_US: "Acoustic Bloc Screens" },
    product_quantity: 5.0,
    line_total_amount: 1475.0,
  },
  {
    sale_order_id: 22,
    sale_order_name: "S00022",
    product_name: { en_US: "Acoustic Bloc Screens" },
    product_quantity: 5.0,
    line_total_amount: 1475.0,
  },
  {
    sale_order_id: 23,
    sale_order_name: "S00023",
    product_name: { en_US: "Acoustic Bloc Screens" },
    product_quantity: 10.0,
    line_total_amount: 2950.0,
  },
  {
    sale_order_id: 7,
    sale_order_name: "S00007",
    product_name: { en_US: "Acoustic Bloc Screens" },
    product_quantity: 5.0,
    line_total_amount: 1475.0,
  },
  {
    sale_order_id: 10,
    sale_order_name: "S00010",
    product_name: { en_US: "Acoustic Bloc Screens" },
    product_quantity: 2.0,
    line_total_amount: 510.0,
  },
  {
    sale_order_id: 11,
    sale_order_name: "S00011",
    product_name: { en_US: "Acoustic Bloc Screens" },
    product_quantity: 3.0,
    line_total_amount: 735.0,
  },
  {
    sale_order_id: 12,
    sale_order_name: "S00012",
    product_name: { en_US: "Acoustic Bloc Screens" },
    product_quantity: 1.0,
    line_total_amount: 315.0,
  },
  {
    sale_order_id: 13,
    sale_order_name: "S00013",
    product_name: { en_US: "Acoustic Bloc Screens" },
    product_quantity: 1.0,
    line_total_amount: 295.0,
  },
  {
    sale_order_id: 14,
    sale_order_name: "S00014",
    product_name: { en_US: "Acoustic Bloc Screens" },
    product_quantity: 4.0,
    line_total_amount: 1100.0,
  },
  {
    sale_order_id: 15,
    sale_order_name: "S00015",
    product_name: { en_US: "Acoustic Bloc Screens" },
    product_quantity: 4.0,
    line_total_amount: 1180.0,
  },
  {
    sale_order_id: 16,
    sale_order_name: "S00016",
    product_name: { en_US: "Acoustic Bloc Screens" },
    product_quantity: 3.0,
    line_total_amount: 825.0,
  },
  {
    sale_order_id: 17,
    sale_order_name: "S00017",
    product_name: { en_US: "Acoustic Bloc Screens" },
    product_quantity: 2.0,
    line_total_amount: 710.0,
  },
  {
    sale_order_id: 18,
    sale_order_name: "S00018",
    product_name: { en_US: "Acoustic Bloc Screens" },
    product_quantity: 2.0,
    line_total_amount: 590.0,
  },
];

export const inventoryData = [
  {
    product_name: { en_US: "Acoustic Bloc Screens" },
    quantity_on_hand: 16.0,
    reserved_quantity: 16.0,
    available_quantity: 0.0,
    unit_price: 295.0,
  },
];
