import React from "react";
import { Chart } from "react-google-charts";

interface GanttChartProps {
  rows: any;
}

const columns = [
  { type: "string", label: "Task ID" },
  { type: "string", label: "Task Name" },
  { type: "string", label: "Resource" },
  { type: "date", label: "Start Date" },
  { type: "date", label: "End Date" },
  { type: "number", label: "Duration" },
  { type: "number", label: "Percent Complete" },
  { type: "string", label: "Dependencies" },
];

export const GanttChartGoogle: React.FC<GanttChartProps> = ({ rows }) => {
  const data = [columns, ...rows];

  return (
    <Chart
      chartType="Gantt"
      width="100%"
      height="100%"
      data={data}
      options={{
        gantt: {
          criticalPathEnabled: true,
          criticalPathStyle: {
            stroke: "#e64a19",
            strokeWidth: 5,
          },
        },
      }}
    />
  );
};
