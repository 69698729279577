import React from "react";
import styles from "./SearchBarBetween.module.css";
import { Search } from "lucide-react";

interface SearchBarBetweenProps {
  placeholder: string;
  inputText: string;
  setInputText: (value: string) => void;
}

export const SearchBarBetween: React.FC<SearchBarBetweenProps> = ({
  placeholder,
  inputText,
  setInputText,
}) => {
  return (
    <div className={styles.searchBar}>
      <input
        type="text"
        placeholder={placeholder}
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
      />
      <Search size={16} />
    </div>
  );
};
