// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProtectedLayout_layout__Osf-z {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.ProtectedLayout_header__CoiYv {
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}

.ProtectedLayout_username__ciH-U {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  position: relative;
}

.ProtectedLayout_dropdown__u4J9p {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 8px;
  width: 100px;
}

.ProtectedLayout_logoutButton__gupf- {
  background: none;
  border: none;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  padding: 5px;
  text-align: left;
}

.ProtectedLayout_logoutButton__gupf-:hover {
  background-color: #f4f4f4;
}

.ProtectedLayout_main__ZZ1Vz {
  flex: 1 1;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/containers/ProtectedLayout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,sBAAsB;EACtB,wCAAwC;EACxC,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,eAAe;EACf,eAAe;EACf,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,SAAO;EACP,gBAAgB;AAClB","sourcesContent":[".layout {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  background-color: #f4f4f4;\n  padding: 10px 20px;\n  border-bottom: 1px solid #ddd;\n}\n\n.username {\n  font-size: 16px;\n  font-weight: bold;\n  color: #333;\n  cursor: pointer;\n  position: relative;\n}\n\n.dropdown {\n  position: absolute;\n  top: 30px;\n  right: 0;\n  background-color: #fff;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 5px;\n  padding: 8px;\n  width: 100px;\n}\n\n.logoutButton {\n  background: none;\n  border: none;\n  color: #333;\n  font-size: 14px;\n  cursor: pointer;\n  width: 100%;\n  padding: 5px;\n  text-align: left;\n}\n\n.logoutButton:hover {\n  background-color: #f4f4f4;\n}\n\n.main {\n  flex: 1;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `ProtectedLayout_layout__Osf-z`,
	"header": `ProtectedLayout_header__CoiYv`,
	"username": `ProtectedLayout_username__ciH-U`,
	"dropdown": `ProtectedLayout_dropdown__u4J9p`,
	"logoutButton": `ProtectedLayout_logoutButton__gupf-`,
	"main": `ProtectedLayout_main__ZZ1Vz`
};
export default ___CSS_LOADER_EXPORT___;
