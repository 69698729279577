import {
  AreaChart,
  BarChart,
  CandlestickChart,
  ChartColumn,
  ChartColumnStacked,
  Filter,
  GanttChart,
  Gauge,
  Grid2X2,
  Grid3x3,
  LineChart,
  ListTree,
  Map,
  PieChart,
  Radar,
  ScatterChart,
  Table,
  TrendingUpDown,
} from "lucide-react";

export const getVisualizationIcons = (visualization: string) => {
  const iconMap: Record<string, (key: string) => JSX.Element> = {
    "Bar Chart": (key) => <BarChart key={key} size={16} />,
    "Line Chart": (key) => <LineChart key={key} size={16} />,
    "Pie Chart": (key) => <PieChart key={key} size={16} />,
    "Stacked Bar Chart": (key) => <ChartColumnStacked key={key} size={16} />,
    "Aging Chart": (key) => <ChartColumnStacked key={key} size={16} />,
    "Area Chart": (key) => <AreaChart key={key} size={16} />,
    "Column Chart": (key) => <ChartColumn key={key} size={16} />,
    "Funnel Chart": (key) => <Filter key={key} size={16} />,
    "Gantt Chart": (key) => <GanttChart key={key} size={16} />,
    Gauge: (key) => <Gauge key={key} size={16} />,
    "Geographic Map": (key) => <Map key={key} size={16} />,
    Heatmap: (key) => <Grid3x3 key={key} size={16} />,
    "Radar Chart": (key) => <Radar key={key} size={16} />,
    "Spider Chart": (key) => <Radar key={key} size={16} />,
    "Risk Matrix": (key) => <Grid2X2 key={key} size={16} />,
    "Sankey Diagram": (key) => <TrendingUpDown key={key} size={16} />,
    "Scatter Plot": (key) => <ScatterChart key={key} size={16} />,
    "Table Chart": (key) => <Table key={key} size={16} />,
    Treemap: (key) => <ListTree key={key} size={16} />,
    "Waterfall Chart": (key) => <CandlestickChart key={key} size={16} />,
    Histogram: (key) => <BarChart key={key} size={16} />,
    "Time Series Chart": (key) => <LineChart key={key} size={16} />,
  };

  return visualization
    .split(";")
    .map((type) => type.trim())
    .map((type, index) => {
      const iconCreator = iconMap[type];
      return iconCreator ? iconCreator(`vis-icon-${index}`) : null;
    })
    .filter(Boolean);
};
