export const validateChartData = (
  chartData: any,
  selectedIndex: string,
  selectedKeys: string[]
): string | null => {
  if (!chartData || !Array.isArray(chartData)) {
    return "Invalid chart data format";
  }
  if (!chartData.length) {
    return "No data available to display";
  }
  if (!selectedIndex) {
    return "No index selected for chart";
  }
  if (!selectedKeys?.length) {
    return "No metrics selected for chart";
  }
  return null;
};
