import React, { ChangeEvent, useState } from "react";
import styles from "./SearchBar.module.css";
import { Search } from "lucide-react";
import { Link } from "react-router-dom";

interface SearchBarProps {
  placeholder: string;
  onSearch?: (value: string) => void;
  results?: {
    id: number;
    name: string;
    type: string;
    category: string;
    description: string;
    visualization: string;
  }[];
}

export const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  onSearch,
  results = [],
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch?.(e.target.value);
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    setTimeout(() => setIsFocused(false), 150);
  };

  return (
    <div className={styles.searchBarContainer}>
      <div className={styles.searchBar}>
        <Search size={16} />
        <input
          type="text"
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
      {isFocused && results.length > 0 && (
        <ul className={styles.results}>
          {results.slice(0, 5).map((result) => (
            <li key={result.id} className={styles.resultItem}>
              <Link to={`/dna/${result.name}`} className={styles.resultLink}>
                <div className={styles.resultName}>{result.name}</div>
                <div className={styles.resultMeta}>
                  <span className={styles.resultType}>{result.type}</span>
                  <span className={styles.resultCategory}>
                    {result.category}
                  </span>
                  <span className={styles.resultVisualization}>
                    {result.visualization}
                  </span>
                </div>
                <div className={styles.resultDescription}>
                  {result.description}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
