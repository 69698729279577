import React, { useEffect, useState } from "react";
import { Info } from "lucide-react";
import { useParams } from "react-router-dom";
import "./KpiAnalyticView.css";
import { DetailBox } from "../../components/boxes/DetailBox/DetailBox";
import { DataBox } from "../../components/boxes/DataBox/DataBox";
import { Loading } from "../Loading";
import {
  dataSourceDetails,
  keyInsightsDetails,
  recommendedActionsDetails,
} from "../../constants/general";
import { renderCharts } from "../../utils/charts/renderCharts";
import { useDnaMutation } from "../../api/ios.api";
import { DialogPopup } from "../../components/dialogs/DialogPopup/DialogPopup";
import { dialogContent } from "../../constants/dialogContent";
import { fetchDiaResponse } from "../../utils/api/dia.api";
import Toast from "../../components/toasts/Toast/Toast";
import { FilterDropdown } from "../../components/dropdowns/FilterDropdown/FilterDropdown";
import { useGenerateResponseMutation } from "../../api/ollama.api";
import axios from "axios";
import { OllamaRequest } from "../../dtos/OllamaRequest.dto";
import { OLLAMA_BASE_URL } from "../../api/constant/const";

export const KpiAnalyticView: React.FC = () => {
  const { dnaParam } = useParams();
  const [dnaData, setDnaData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDialogContent, setSelectedDialogContent] = useState<any>(null);
  const [inputText, setInputText] = useState<string>("");
  const [selectedContent, setSelectedContent] = useState<any>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [selectedIndex, setSelectedIndex] = useState<string | null>(null);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const [dna] = useDnaMutation();
  const [generateResponse] = useGenerateResponseMutation();

  useEffect(() => {
    const fetchKpi = async () => {
      try {
        const response = await dna(dnaParam!);
        if ("data" in response) {
          setDnaData(response?.data[0]);
          if (!response.data[0].data || response.data[0].data.length === 0) {
            setToastMessage("No data available for this KPI");
            setToastType("error");
            return;
          }
        } else {
          console.error("Error fetching kpis:", response.error);
          setToastMessage(`No data available for visualization`);
          setToastType("error");
        }
      } catch (err) {
        setToastMessage("An unexpected error occurred while fetching KPI data");
        setToastType("error");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchKpi();
  }, [dna, dnaParam]);

  useEffect(() => {
    if (dnaData?.data?.length > 0) {
      const indexByOptions = Object.keys(dnaData.data[0] || {}).flatMap(
        (key) => {
          if (
            (key === "product_name" &&
              typeof dnaData.data[0]?.[key]?.en_US === "string") ||
            (key === "stage_name" &&
              typeof dnaData.data[0]?.[key]?.en_US === "string")
          ) {
            return [key];
          }
          if (
            (typeof dnaData.data[0]?.[key] === "number" &&
              key.toLowerCase() === "month") ||
            key.toLowerCase() === "year"
          ) {
            return key.toLowerCase().includes("month") ? [key] : [];
          }
          if (
            typeof dnaData.data[0]?.[key] === "number" &&
            key.toLowerCase().includes("id")
          ) {
            return [key];
          }
          return typeof dnaData.data[0]?.[key] === "string" ? [key] : [];
        }
      );

      const keys = Object.keys(dnaData.data[0] || {}).filter((key) => {
        if (typeof dnaData.data[0]?.[key] === "number") {
          return !(
            key.toLowerCase() === "month" ||
            key.toLowerCase() === "year" ||
            key.toLowerCase().includes("id")
          );
        }
        return false;
      });

      if (indexByOptions.length > 0 && !selectedIndex) {
        setSelectedIndex(indexByOptions[0]);
        setSelectedKeys([keys[0]]);
      }
    }
  }, [dnaData, selectedIndex, selectedKeys]);

  if (loading) {
    return <Loading />;
  }

  const toggleDialog = (name?: string) => {
    if (name) {
      const content = dialogContent.find((item) => item.name === name);
      setSelectedDialogContent(content || null);
    }
    setIsDialogOpen(!isDialogOpen);
  };

  const askButtonClick = async () => {
    try {
      setButtonLoading(true);
      const promptResponse = await fetchDiaResponse(dnaData?.name, inputText);
      // const responseAI: any = await generateResponse(promptResponse);
      // console.log(responseAI?.data?.response);
      const responseAI = await axios.post(`${OLLAMA_BASE_URL}/api/generate`, {
        model: "qwen2.5",
        prompt: promptResponse,
        stream: false,
      } as OllamaRequest);
      setSelectedContent(responseAI?.data?.response);
    } catch (error) {
      console.error("Error fetching DIA response:", error);
      setButtonLoading(false);
      setToastMessage(
        "The types of data available won't produce a clear visualization right now - please wait until more data is available."
      );
      setToastType("error");
    } finally {
      setButtonLoading(false);
    }
  };

  const handleSelectIndex = (index: string) => setSelectedIndex(index);

  const handleToggleKey = (key: string) => {
    setSelectedKeys((prev) =>
      prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
    );
  };

  return (
    <div className="Page">
      <div className="Header">
        <div className="HeaderTitle">
          <div className="HeaderTitleIcon">
            <h1>{dnaData?.name}</h1>
            <Info
              size={32}
              onClick={() => toggleDialog(dnaData?.name)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <FilterDropdown
          data={dnaData?.data}
          selectedIndex={selectedIndex}
          selectedKeys={selectedKeys}
          onSelectIndex={handleSelectIndex}
          onToggleKey={handleToggleKey}
        />
      </div>
      <div className="Main">
        {renderCharts(
          dnaData?.visual,
          dnaData?.data,
          selectedIndex || "",
          selectedKeys
        )}
        <DetailBox {...dataSourceDetails} />
        <DetailBox {...keyInsightsDetails} />
        <DetailBox {...recommendedActionsDetails} />
        <DataBox
          onClick={askButtonClick}
          inputText={inputText}
          setInputText={setInputText}
          data={selectedContent}
          buttonDisabled={buttonLoading}
        />
      </div>

      <DialogPopup
        isOpen={isDialogOpen}
        onClose={toggleDialog}
        data={selectedDialogContent}
      />
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          onClose={() => setToastMessage("")}
        />
      )}
    </div>
  );
};
