import React from "react";
import { ResponsiveHeatMap } from "@nivo/heatmap";

interface RiskMatrixProps {
  data: {
    id: string;
    data: {
      x: string;
      y: number;
    }[];
  }[];
  indexBy?: string;
}

export const RiskMatrixNivo: React.FC<RiskMatrixProps> = ({
  data,
  indexBy = "vendor_name",
}) => {
  return (
    <ResponsiveHeatMap
      data={data}
      margin={{ top: 90, right: 90, bottom: 60, left: 90 }}
      valueFormat={(value) => {
        if (value <= 33) return "High Risk";
        if (value <= 66) return "Medium Risk";
        return "Low Risk";
      }}
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        legend: "Risk Factors",
        legendOffset: 46,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: indexBy,
        legendPosition: "middle",
        legendOffset: -72,
      }}
      colors={{
        type: "diverging",
        scheme: "red_yellow_green",
        minValue: 0,
        maxValue: 100,
        divergeAt: 0.5,
      }}
      emptyColor="#555555"
    />
  );
};
