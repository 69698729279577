import React, { useState, useEffect, useRef } from "react";
import { SlidersHorizontal, X } from "lucide-react";
import styles from "./FilterDropdown.module.css";

interface DropdownProps {
  data: any[];
  selectedIndex: string | null;
  selectedKeys: string[];
  onSelectIndex: (index: string) => void;
  onToggleKey: (key: string) => void;
}

export const FilterDropdown: React.FC<DropdownProps> = ({
  data,
  selectedIndex,
  selectedKeys,
  onSelectIndex,
  onToggleKey,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const indexByOptions = Object?.keys(data?.[0] || {})?.filter((key) => {
    if (
      (key === "product_name" && typeof data?.[0]?.[key]?.en_US === "string") ||
      (key === "stage_name" && typeof data?.[0]?.[key]?.en_US === "string")
    ) {
      return [key];
    }
    if (
      (typeof data?.[0]?.[key] === "number" && key.toLowerCase() === "month") ||
      key.toLowerCase() === "year"
    ) {
      return key.toLowerCase().includes("month");
    }
    if (
      typeof data?.[0]?.[key] === "number" &&
      key.toLowerCase().includes("id")
    ) {
      return [key];
    }
    return typeof data?.[0]?.[key] === "string";
  });

  const keyOptions = Object.keys(data?.[0] || {}).filter((key) => {
    if (typeof data?.[0]?.[key] === "number") {
      return !(
        key.toLowerCase() === "month" ||
        key.toLowerCase() === "year" ||
        key.toLowerCase().includes("id")
      );
    }
    return false;
  });

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.filterControls}>
        <span className={styles.helperText}>Click to filter chart data</span>
        <SlidersHorizontal
          size={32}
          style={{ cursor: "pointer" }}
          onClick={toggleDropdown}
        />
      </div>
      {isOpen && (
        <div className={styles.dropdownContent} ref={dropdownRef}>
          <div className={styles.dropdownHeader}>
            <h3>Filter Options</h3>
            <X
              size={32}
              className={styles.closeButton}
              onClick={() => setIsOpen(false)}
            />
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>INDEX BY</th>
                <th>KEYS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {indexByOptions.map((option, idx) => (
                    <div key={idx} className={styles.radioOption}>
                      <input
                        type="radio"
                        id={`index-${option}`}
                        name="index"
                        value={option}
                        checked={selectedIndex === option}
                        onChange={() => onSelectIndex(option)}
                      />
                      <label htmlFor={`index-${option}`}>{option}</label>
                    </div>
                  ))}
                </td>
                <td>
                  {keyOptions.map((key, idx) => (
                    <div key={idx} className={styles.checkboxOption}>
                      <input
                        type="checkbox"
                        id={`key-${idx}`}
                        value={key}
                        checked={selectedKeys.includes(key)}
                        onChange={() => onToggleKey(key)}
                      />
                      <label htmlFor={`key-${idx}`}>{key}</label>
                    </div>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
