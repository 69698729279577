import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl } from "../utils/url";
import { getToken } from "../utils/token";

export const dataApi = createApi({
  reducerPath: "dataApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl() + "/data",
    headers: {
      Authorization: `${getToken()}`,
    },
  }),
  endpoints: (build) => ({
    inventory: build.mutation<any, void>({
      query: () => ({
        url: "/inventory",
        method: "GET",
      }),
    }),
    sale: build.mutation<any, void>({
      query: () => ({
        url: "/sale",
        method: "GET",
      }),
    }),
    purchase: build.mutation<any, void>({
      query: () => ({
        url: "/purchase",
        method: "GET",
      }),
    }),
  }),
});

export const { useInventoryMutation, useSaleMutation, usePurchaseMutation } =
  dataApi;
