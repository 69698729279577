import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { authApi } from "../api/auth.api";
import { iosApi } from "../api/ios.api";
import { dataApi } from "../api/data.api";
import auth from "../slices/auth.slice";
import { ollamaApi } from "../api/ollama.api";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [iosApi.reducerPath]: iosApi.reducer,
    [dataApi.reducerPath]: dataApi.reducer,
    [ollamaApi.reducerPath]: ollamaApi.reducer,
    auth,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(iosApi.middleware)
      .concat(dataApi.middleware)
      .concat(ollamaApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
