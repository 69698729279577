import React from "react";
import styles from "./ChartBox.module.css";

interface ChartBoxProps {
  title: string;
  chart: React.ReactNode;
  height?: number | string;
}

export const ChartBox: React.FC<ChartBoxProps> = ({
  title,
  chart,
  height = "600px",
}) => {
  return (
    <div className={styles.box}>
      <h4>{title}</h4>
      <div className={styles.chart} style={{ height: height }}>
        {chart}
      </div>
    </div>
  );
};
