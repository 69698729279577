import { ResponsivePie } from "@nivo/pie";

interface PieChartNivoProps {
  data: {
    id: string;
    value: number;
  }[];
}

export const PieChartNivo: React.FC<PieChartNivoProps> = ({ data }) => (
  <ResponsivePie
    data={data.filter((item) => item.value !== 0)}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    padAngle={0.7}
    cornerRadius={3}
    activeOuterRadiusOffset={8}
    colors={{ scheme: "category10" }}
    borderWidth={1}
    borderColor={{
      from: "color",
      modifiers: [["darker", 0.2]],
    }}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: "color" }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor={{
      from: "color",
      modifiers: [["darker", 2]],
    }}
    legends={[
      {
        anchor: "right",
        direction: "column",
        justify: false,
        translateX: 70,
        translateY: 0,
        itemWidth: 60,
        itemHeight: 14,
        itemsSpacing: 2,
        symbolSize: 14,
        itemDirection: "left-to-right",
        symbolShape: "circle",
      },
    ]}
  />
);
