import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl } from "../utils/url";
import { getToken } from "../utils/token";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "",
    fetchFn: async () => {
      const url = getUrl();
      const token = getToken();
      if (!url) {
        throw new Error("API base URL is not set.");
      }

      return await fetch(url, {
        headers: {
          Authorization: `${token}`,
        },
      });
    },
  }),
  endpoints: (build) => ({
    me: build.mutation<any, void>({
      query: () => ({
        url: "/",
        method: "GET",
      }),
    }),
  }),
});

export const { useMeMutation } = authApi;
