export const getUrl = () => {
  return localStorage.getItem("url") || "";
};

export const removeUrl = () => {
  localStorage.removeItem("url");
};

export const setUrl = (value: string) => {
  localStorage.setItem("url", value);
};
