import React, { useState } from "react";
import styles from "./KpiBox.module.css";
import { Link } from "react-router-dom";
import { getVisualizationIcons } from "../../../utils/getVisualizationIcons";
import { ChevronDown, ChevronUp, Info } from "lucide-react";

export interface KpiBoxProps {
  title: string;
  description: string;
  type: string;
  category: string;
  visualization?: string;
  factors?: string;
  link?: string;
  onClick?: (name: string) => void;
}

export const KpiBox: React.FC<KpiBoxProps> = ({
  title,
  description,
  type,
  category,
  visualization,
  factors,
  link = "/dna/dpo",
  onClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`${styles.box} ${isExpanded ? styles.expanded : ""}`}>
      <div
        className={styles.content}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className={styles.header}>
          <div className={styles.titleSection}>
            <h3>{title}</h3>
            <div className={styles.badges}>
              <span className={styles.typeBadge}>{type}</span>
              <span className={styles.categoryBadge}>{category}</span>
            </div>
          </div>
          <Info
            size={20}
            className={styles.infoIcon}
            onClick={(e) => {
              e.stopPropagation();
              onClick?.(title);
            }}
          />
        </div>

        <p className={styles.description}>{description}</p>

        {visualization && (
          <div className={styles.visualizationTags}>
            {getVisualizationIcons(visualization).map((icon, index) => (
              <span key={`vis-${index}`}>{icon}</span>
            ))}
          </div>
        )}

        {isExpanded && (
          <div className={styles.expandedContent}>
            {factors && (
              <div className={styles.factorsSection}>
                <h4>External Factors</h4>
                <p>{factors}</p>
              </div>
            )}
          </div>
        )}
      </div>

      <div className={styles.footer}>
        <Link to={link} className={styles.viewMoreLink}>
          View Analysis
        </Link>
        <button
          className={styles.expandButton}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </button>
      </div>
    </div>
  );
};
