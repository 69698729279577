// export const prompts = {
//   Explain_KPI_Accounts_Payable_Analytics_Aging_of_Receivables:
//     "Tell me what a Aging of Receivables report should be, expand on the information from Breakdown of outstanding receivables by age.\n\nDescribe the predictives listed in Predicting collections based on aging trends. and explain why it is important, and how external factors listed in Changes in customer payment behavior; economic factors. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Aging of Receivables\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_Analytics_Collection_Effectiveness_Index:
//     "Tell me what a Collection Effectiveness Index report should be, expand on the information from Evaluates the effectiveness of the collections process.\n\nDescribe the predictives listed in Forecasting future collection efforts based on historical data. and explain why it is important, and how external factors listed in Market conditions; customer financial health. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Collection Effectiveness Index\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_Analytics_Cost_per_Invoice:
//     "Tell me what a Cost per Invoice report should be, expand on the information from Processing cost per invoice; identifies cost reduction opportunities.\n\nDescribe the predictives listed in Projecting potential savings from automation. and explain why it is important, and how external factors listed in Technological advancements; invoice volume. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Cost per Invoice\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_Analytics_Invoice_Processing_Time:
//     "Tell me what a Invoice Processing Time report should be, expand on the information from Average time taken to process invoices.\n\nDescribe the predictives listed in Analyzing process efficiency to enhance payment cycles. and explain why it is important, and how external factors listed in Employee efficiency; technology systems. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Invoice Processing Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_Analytics_Payment_Trends:
//     "Tell me what a Payment Trends report should be, expand on the information from Analyzes payment patterns and behaviors.\n\nDescribe the predictives listed in Identifying trends for future cash flow predictions. and explain why it is important, and how external factors listed in Changes in supplier payment terms; discounts offered. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Payment Trends\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_Analytics_Supplier_Analysis:
//     "Tell me what a Supplier Analysis report should be, expand on the information from Analysis of key supplier metrics.\n\nDescribe the predictives listed in Identifying reliable suppliers for negotiation leverage. and explain why it is important, and how external factors listed in Industry standards; supplier market conditions. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Supplier Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_Analytics_Variance_Analysis:
//     "Tell me what a Variance Analysis report should be, expand on the information from Compares expected vs actual spending; monitors discrepancies.\n\nDescribe the predictives listed in Identifying discrepancies for enhanced financial planning. and explain why it is important, and how external factors listed in Economic conditions impacting supplier pricing. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Variance Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_KPI_Aging_Reports:
//     "Tell me what a Aging Reports report should be, expand on the information from Breakdown of accounts payable by age; identifies overdue payments.\n\nDescribe the predictives listed in Projected cash outflows based on outstanding liabilities. and explain why it is important, and how external factors listed in Supplier payment terms; cash flow position. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Aging Reports\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_KPI_Cost_per_Invoice:
//     "Tell me what a Cost per Invoice report should be, expand on the information from Average cost of processing each invoice.\n\nDescribe the predictives listed in Analyzing cost trends for efficiencies through automation. and explain why it is important, and how external factors listed in Labor costs; technology used for processing invoices. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Cost per Invoice\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_KPI_Days_Payable_Outstanding_DPO:
//     "Tell me what a Days Payable Outstanding (DPO) report should be, expand on the information from Measures average number of days to pay invoices.\n\nDescribe the predictives listed in Forecasting future liquidity based on payment trends. and explain why it is important, and how external factors listed in Payment terms negotiated with suppliers; overall cash flow. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Days Payable Outstanding (DPO)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_KPI_Early_Payment_Discounts_Captured:
//     "Tell me what a Early Payment Discounts Captured report should be, expand on the information from Evaluates percentage of early discounts utilized versus available.\n\nDescribe the predictives listed in Assessing potential savings based on current relationships. and explain why it is important, and how external factors listed in Supplier willingness to offer discounts; available funds. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Early Payment Discounts Captured\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_KPI_Invoice_Exception_Rate:
//     "Tell me what a Invoice Exception Rate report should be, expand on the information from Percentage of invoices needing manual intervention due to discrepancies.\n\nDescribe the predictives listed in Identifying process improvements to reduce exceptions. and explain why it is important, and how external factors listed in Data entry issues; supplier invoicing practices. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Invoice Exception Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_KPI_Invoice_Processing_Time:
//     "Tell me what a Invoice Processing Time report should be, expand on the information from Measures time taken to process each invoice from receipt to payment.\n\nDescribe the predictives listed in Analysis of efficiency to enhance payment cycles. and explain why it is important, and how external factors listed in Workload fluctuations; employee efficiency. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Invoice Processing Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_KPI_Late_Payment_Penalties:
//     "Tell me what a Late Payment Penalties report should be, expand on the information from Tracks costs incurred from late payments.\n\nDescribe the predictives listed in Monitoring historical penalty trends for future forecasts. and explain why it is important, and how external factors listed in Changes in payment processing systems; invoice accuracy. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Late Payment Penalties\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_KPI_Payment_Accuracy_Rate:
//     "Tell me what a Payment Accuracy Rate report should be, expand on the information from Percentage of payments made accurately without errors.\n\nDescribe the predictives listed in Projecting potential cost savings from improved accuracy. and explain why it is important, and how external factors listed in Quality of data; employee training. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Payment Accuracy Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_KPI_Spending_Analysis_by_Category:
//     "Tell me what a Spending Analysis by Category report should be, expand on the information from Tracks spending across categories to identify trends and savings.\n\nDescribe the predictives listed in Identifying potential savings opportunities based on patterns. and explain why it is important, and how external factors listed in Market conditions affecting pricing changes. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Spending Analysis by Category\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Payable_KPI_Supplier_Payment_Terms:
//     "Tell me what a Supplier Payment Terms report should be, expand on the information from Analyzes agreed payment terms with suppliers and tracks compliance.\n\nDescribe the predictives listed in Future negotiation strategies based on compliance history. and explain why it is important, and how external factors listed in Supplier negotiation tactics; economic conditions. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Supplier Payment Terms\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Receivable_Analytics_Aging_of_Receivables:
//     "Tell me what a Aging of Receivables report should be, expand on the information from Breakdown of outstanding receivables by age to identify collection issues.\n\nDescribe the predictives listed in Projecting cash inflows based on aging trends. and explain why it is important, and how external factors listed in Customer payment behavior; dynamics in industry credit practices. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Aging of Receivables\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Receivable_Analytics_Cash_Conversion_Cycle_CCC:
//     "Tell me what a Cash Conversion Cycle (CCC) report should be, expand on the information from Analyzes time taken to convert resource investments into cash flows.\n\nDescribe the predictives listed in Assisting in cash flow management predictions. and explain why it is important, and how external factors listed in Supplier terms; sales cycle length. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Cash Conversion Cycle (CCC)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Receivable_Analytics_Collection_Effectiveness_Index:
//     "Tell me what a Collection Effectiveness Index report should be, expand on the information from Evaluates the effectiveness of the collections process.\n\nDescribe the predictives listed in Forecasting future effectiveness based on historical collection performance. and explain why it is important, and how external factors listed in Market conditions; economic factors. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Collection Effectiveness Index\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Receivable_Analytics_Credit_Risk_Analysis:
//     "Tell me what a Credit Risk Analysis report should be, expand on the information from Assesses risk associated with extending credit to customers.\n\nDescribe the predictives listed in Guiding credit policies based on past behavior of customers. and explain why it is important, and how external factors listed in Industry trends; economic cycles. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Credit Risk Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Receivable_Analytics_Customer_Payment_Trends:
//     "Tell me what a Customer Payment Trends report should be, expand on the information from Analyzes payment behaviors and patterns of customers.\n\nDescribe the predictives listed in Forecasting future collections based on historical payment behavior. and explain why it is important, and how external factors listed in Changes in customer payment terms; economic stability. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Customer Payment Trends\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Receivable_Analytics_Dispute_Resolution_Time:
//     "Tell me what a Dispute Resolution Time report should be, expand on the information from Tracks the time taken to resolve billing disputes with customers.\n\nDescribe the predictives listed in Predicting potential delays in cash inflows based on resolution times. and explain why it is important, and how external factors listed in Quality of invoicing process; customer relations. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Dispute Resolution Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Receivable_KPI_Average_Days_Delinquent_ADD:
//     "Tell me what a Average Days Delinquent (ADD) report should be, expand on the information from Average number of days payments are past due.\n\nDescribe the predictives listed in Predicting future cash flow issues based on trends in delinquency. and explain why it is important, and how external factors listed in Changes in customer financial health; market conditions. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Average Days Delinquent (ADD)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Receivable_KPI_Bad_Debt_to_Sales_Ratio:
//     "Tell me what a Bad Debt to Sales Ratio report should be, expand on the information from Proportion of account receivables written off as bad debt.\n\nDescribe the predictives listed in Forecasting potential future bad debt based on trends. and explain why it is important, and how external factors listed in Economic downturns; customer creditworthiness. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Bad Debt to Sales Ratio\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Receivable_KPI_Days_Sales_Outstanding_DSO:
//     "Tell me what a Days Sales Outstanding (DSO) report should be, expand on the information from Measures average days to collect payment after sales.\n\nDescribe the predictives listed in Forecasting collection timeline based on historical data. and explain why it is important, and how external factors listed in Customer payment behaviors; economic conditions. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Days Sales Outstanding (DSO)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Receivable_KPI_Invoice_Dispute_Rate:
//     "Tell me what a Invoice Dispute Rate report should be, expand on the information from Percentage of invoices disputed by customers.\n\nDescribe the predictives listed in Identifying potential issues in the invoicing process. and explain why it is important, and how external factors listed in Customer satisfaction and relations; clarity of invoices. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Invoice Dispute Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Accounts_Receivable_KPI_Receivables_Turnover_Ratio:
//     "Tell me what a Receivables Turnover Ratio report should be, expand on the information from Measures effectiveness of using receivables for generating sales.\n\nDescribe the predictives listed in Forewarning of potential cash flow issues due to low turnover. and explain why it is important, and how external factors listed in Economic conditions; sales strategies. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Receivables Turnover Ratio\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Asset_Management_Analytics_Asset_Condition_Assessment:
//     "Tell me what a Asset Condition Assessment report should be, expand on the information from Evaluates the current condition of assets to inform maintenance decisions.\n\nDescribe the predictives listed in Forecasting potential maintenance needs based on asset conditions. and explain why it is important, and how external factors listed in Aging assets; usage patterns. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Asset Condition Assessment\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Asset_Management_Analytics_Compliance_and_Risk_Management:
//     "Tell me what a Compliance and Risk Management report should be, expand on the information from Tracks adherence to regulations and identifies risks associated with asset management.\n\nDescribe the predictives listed in Improving risk management strategies based on compliance results. and explain why it is important, and how external factors listed in Regulatory changes; market conditions. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Compliance and Risk Management\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Asset_Management_Analytics_Depreciation_Tracking:
//     "Tell me what a Depreciation Tracking report should be, expand on the information from Monitors the depreciation of assets over time.\n\nDescribe the predictives listed in Forecasting asset value changes over its lifecycle. and explain why it is important, and how external factors listed in Economic factors; changes in asset utilization. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Depreciation Tracking\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Asset_Management_Analytics_Maintenance_Frequency_Analysis:
//     "Tell me what a Maintenance Frequency Analysis report should be, expand on the information from Analyzes the frequency of asset maintenance activities.\n\nDescribe the predictives listed in Improving maintenance scheduling based on historical data. and explain why it is important, and how external factors listed in Operational demands; asset performance. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Maintenance Frequency Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Asset_Management_Analytics_Replacement_Cost_Analysis:
//     "Tell me what a Replacement Cost Analysis report should be, expand on the information from Calculates the cost to replace an asset at current market rates.\n\nDescribe the predictives listed in Guiding budgeting for future asset replacements based on market trends. and explain why it is important, and how external factors listed in Supply chain factors; market volatility. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Replacement Cost Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Asset_Management_KPI_Asset_Age:
//     "Tell me what a Asset Age report should be, expand on the information from Tracks the average age of assets to assess potential obsolescence.\n\nDescribe the predictives listed in Forecasting replacement or maintenance schedules. and explain why it is important, and how external factors listed in Technological advancements; changes in industry standards. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Asset Age\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Asset_Management_KPI_Asset_Disposal_Rate:
//     "Tell me what a Asset Disposal Rate report should be, expand on the information from Measures the efficiency of disposing of assets that have reached end-of-life.\n\nDescribe the predictives listed in Forecasting disposal needs and asset replacement cycles. and explain why it is important, and how external factors listed in Market demand for used assets; regulatory changes. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Asset Disposal Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Asset_Management_KPI_Asset_Utilization_Rate:
//     "Tell me what a Asset Utilization Rate report should be, expand on the information from Measures efficiency of utilizing assets to generate revenue.\n\nDescribe the predictives listed in Predicting future revenue generation capabilities based on asset use. and explain why it is important, and how external factors listed in Operational efficiency; investment in new technology. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Asset Utilization Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Asset_Management_KPI_Maintenance_Cost_as_a_Percentage_of_Asset_Value:
//     "Tell me what a Maintenance Cost as a Percentage of Asset Value report should be, expand on the information from Assesses costs associated with maintaining assets.\n\nDescribe the predictives listed in Identifying areas for cost control in maintenance budgeting. and explain why it is important, and how external factors listed in Age of assets; maintenance practices. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Maintenance Cost as a Percentage of Asset Value\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Asset_Management_KPI_Return_on_Assets_ROA:
//     "Tell me what a Return on Assets (ROA) report should be, expand on the information from Measures profitability in relation to total assets.\n\nDescribe the predictives listed in Forecasting profitability trends based on asset utilization. and explain why it is important, and how external factors listed in Market conditions; management efficiency. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Return on Assets (ROA)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Cash_Bank_Management_Analytics_Bank_Fees_Analysis:
//     "Tell me what a Bank Fees Analysis report should be, expand on the information from Analyzes fees incurred from banking transactions to identify cost-saving opportunities.\n\nDescribe the predictives listed in Evaluating banking agreements for potential renegotiation opportunities. and explain why it is important, and how external factors listed in Changes in banking policies; volume of banking transactions. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Bank Fees Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Cash_Bank_Management_Analytics_Cash_Conversion_Cycle_CCC:
//     "Tell me what a Cash Conversion Cycle (CCC) report should be, expand on the information from Analyzes the time taken to convert resource investments into cash flows.\n\nDescribe the predictives listed in Predicting cash flow peaks based on operational efficiency changes. and explain why it is important, and how external factors listed in Supplier and customer payment terms; market demand. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Cash Conversion Cycle (CCC)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Cash_Bank_Management_Analytics_Cash_Flow_Variance_Analysis:
//     "Tell me what a Cash Flow Variance Analysis report should be, expand on the information from Compares projected vs actual cash flows to assess discrepancies.\n\nDescribe the predictives listed in Adjusting financial strategies based on cash flow performance. and explain why it is important, and how external factors listed in Economic conditions; operational performance. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Cash Flow Variance Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Cash_Bank_Management_Analytics_Fraud_Detection_and_Prevention:
//     "Tell me what a Fraud Detection and Prevention report should be, expand on the information from Monitors and analyzes data to identify and prevent fraudulent activities.\n\nDescribe the predictives listed in Improving security measures based on past fraud incidents. and explain why it is important, and how external factors listed in Regulatory changes; economic environment. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Fraud Detection and Prevention\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Cash_Bank_Management_Analytics_Investment_Income:
//     "Tell me what a Investment Income report should be, expand on the information from Measures the returns generated from the company's short-term investments.\n\nDescribe the predictives listed in Forecasting future investment opportunities based on historical performance. and explain why it is important, and how external factors listed in Interest rate fluctuations; market conditions. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Investment Income\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Cash_Bank_Management_KPI_Bank_Reconciliation_Time:
//     "Tell me what a Bank Reconciliation Time report should be, expand on the information from Tracks the time taken to complete bank reconciliations.\n\nDescribe the predictives listed in Identifying process efficiencies to streamline cash management. and explain why it is important, and how external factors listed in Volume of transactions; complexity of accounts. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Bank Reconciliation Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Cash_Bank_Management_KPI_Cash_Flow_Forecasting_Accuracy:
//     "Tell me what a Cash Flow Forecasting Accuracy report should be, expand on the information from Measures the precision of cash flow predictions compared to actual cash flows.\n\nDescribe the predictives listed in Improving forecasting methodologies based on past prediction accuracy. and explain why it is important, and how external factors listed in Market trends; changes in business operations. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Cash Flow Forecasting Accuracy\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Cash_Bank_Management_KPI_Days_Cash_on_Hand:
//     "Tell me what a Days Cash on Hand report should be, expand on the information from Indicates the number of days the company can pay its expenses with current cash.\n\nDescribe the predictives listed in Assessing cash reserves to cover operational expenses in the short term. and explain why it is important, and how external factors listed in Changes in operational costs; revenue fluctuations. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Days Cash on Hand\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Cash_Bank_Management_KPI_Interest_Income_Expense_Analysis:
//     "Tell me what a Interest Income/Expense Analysis report should be, expand on the information from Assesses the income generated from interest or costs incurred from interest on debts.\n\nDescribe the predictives listed in Forecasting future cash flow from interest on investments and debts. and explain why it is important, and how external factors listed in Interest rates; changes in market investment opportunities. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Interest Income/Expense Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Cash_Bank_Management_KPI_Liquidity_Ratio:
//     "Tell me what a Liquidity Ratio report should be, expand on the information from Evaluates the company's ability to meet short-term liabilities with liquid assets.\n\nDescribe the predictives listed in Forecasting cash flow requirements based on liquidity levels. and explain why it is important, and how external factors listed in Economic conditions; market volatility. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Liquidity Ratio\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_General_Ledger_Analytics_Data_Integrity:
//     "Tell me what a Data Integrity report should be, expand on the information from Assesses the accuracy and completeness of data entered.\n\nDescribe the predictives listed in Improving data entry processes based on integrity checks. and explain why it is important, and how external factors listed in Quality of source data; training practices. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Data Integrity\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_General_Ledger_Analytics_Intercompany_Reconciliation_Time:
//     "Tell me what a Intercompany Reconciliation Time report should be, expand on the information from Measures the time required to reconcile intercompany transactions.\n\nDescribe the predictives listed in Identifying efficiencies in intercompany accounting processes. and explain why it is important, and how external factors listed in Volume of transactions; intercompany policies. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Intercompany Reconciliation Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_General_Ledger_Analytics_Internal_Audit_Findings:
//     "Tell me what a Internal Audit Findings report should be, expand on the information from Tracks the number and significance of issues identified during internal audits.\n\nDescribe the predictives listed in Identifying areas of improvement based on audit outcomes. and explain why it is important, and how external factors listed in Compliance requirements; operational risks. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Internal Audit Findings\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_General_Ledger_Analytics_Regulatory_Compliance:
//     "Tell me what a Regulatory Compliance report should be, expand on the information from Monitors adherence to financial regulations and standards.\n\nDescribe the predictives listed in Forecasting compliance risks based on historical data. and explain why it is important, and how external factors listed in Changes in regulatory requirements; industry standards. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Regulatory Compliance\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_General_Ledger_Analytics_Variance_Analysis:
//     "Tell me what a Variance Analysis report should be, expand on the information from Compares expected versus actual figures to identify anomalies.\n\nDescribe the predictives listed in Guiding financial control measures based on variance trends. and explain why it is important, and how external factors listed in Budgets; market conditions. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Variance Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_General_Ledger_KPI_Accuracy_of_Financial_Statements:
//     "Tell me what a Accuracy of Financial Statements report should be, expand on the information from Evaluates the correctness of reported financial data.\n\nDescribe the predictives listed in Identifying trends in reporting errors for future prevention. and explain why it is important, and how external factors listed in Data entry practices; training quality. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Accuracy of Financial Statements\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_General_Ledger_KPI_Balance_Sheet_Reconciliation_Time:
//     "Tell me what a Balance Sheet Reconciliation Time report should be, expand on the information from Tracks the time taken to reconcile balance sheet accounts.\n\nDescribe the predictives listed in Forecasting efficiencies in reconciliation processes. and explain why it is important, and how external factors listed in Volume of transactions; information availability. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Balance Sheet Reconciliation Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_General_Ledger_KPI_Chart_of_Accounts_Utilization:
//     "Tell me what a Chart of Accounts Utilization report should be, expand on the information from Analyzes how effectively the chart of accounts is used.\n\nDescribe the predictives listed in Improving chart structure and reducing redundancy. and explain why it is important, and how external factors listed in Business activities; changes in accounting standards. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Chart of Accounts Utilization\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_General_Ledger_KPI_Financial_Close_Time:
//     "Tell me what a Financial Close Time report should be, expand on the information from Measures the time taken to close the books at the end of a financial period.\n\nDescribe the predictives listed in Assessing efficiency of the closing process for future periods. and explain why it is important, and how external factors listed in Staff efficiency; transaction volume. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Financial Close Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_General_Ledger_KPI_Journal_Entry_Error_Rate:
//     "Tell me what a Journal Entry Error Rate report should be, expand on the information from Calculates the percentage of incorrect journal entries that require adjustment.\n\nDescribe the predictives listed in Monitoring accuracy for continuous improvement in entry processes. and explain why it is important, and how external factors listed in Staff training; complexity of transactions. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Journal Entry Error Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Inventory_Management_Analytics_Demand_Variability_Analysis:
//     "Tell me what a Demand Variability Analysis report should be, expand on the information from Tracks fluctuations in consumer demand over time.\n\nDescribe the predictives listed in Guiding inventory adjustment strategies based on demand patterns. and explain why it is important, and how external factors listed in Sales promotions; economic cycles; competitor actions. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Demand Variability Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Inventory_Management_Analytics_Inventory_Valuation:
//     "Tell me what a Inventory Valuation report should be, expand on the information from Assessing the value of all stock based on accounting methods.\n\nDescribe the predictives listed in Forecasting financial performance through correct inventory valuations. and explain why it is important, and how external factors listed in Adoption of accounting standards; market prices. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Inventory Valuation\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Inventory_Management_Analytics_Reorder_Point_Analysis:
//     "Tell me what a Reorder Point Analysis report should be, expand on the information from Calculates minimum stock level before reordering.\n\nDescribe the predictives listed in Optimizing reorder levels to avoid stockouts. and explain why it is important, and how external factors listed in Supplier reliability; fluctuations in demand and lead times. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Reorder Point Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Inventory_Management_Analytics_Sales_Forecasting:
//     "Tell me what a Sales Forecasting report should be, expand on the information from Predicts future product sales based on historical data and trends.\n\nDescribe the predictives listed in Guiding production and purchasing decisions. and explain why it is important, and how external factors listed in Market trends; economic conditions; promotional activities. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Sales Forecasting\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Inventory_Management_Analytics_Stock_Aging_Analysis:
//     "Tell me what a Stock Aging Analysis report should be, expand on the information from Breakdown of inventory age to identify slow-moving items.\n\nDescribe the predictives listed in Predicting obsolescence and future markdown needs. and explain why it is important, and how external factors listed in Changes in consumer preferences; technological advancements. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Stock Aging Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Inventory_Management_KPI_Carrying_Cost_of_Inventory:
//     "Tell me what a Carrying Cost of Inventory report should be, expand on the information from Total cost of storing and managing inventory.\n\nDescribe the predictives listed in Forecasting the financial impact of holding excess inventory. and explain why it is important, and how external factors listed in Storage costs; insurance rates; inventory losses. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Carrying Cost of Inventory\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Inventory_Management_KPI_Days_Inventory_Outstanding_DIO:
//     "Tell me what a Days Inventory Outstanding (DIO) report should be, expand on the information from Average number of days inventory is held before being sold.\n\nDescribe the predictives listed in Identifying potential excess inventory issues. and explain why it is important, and how external factors listed in Market demand; lead times for suppliers. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Days Inventory Outstanding (DIO)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Inventory_Management_KPI_Inventory_Turnover_Ratio:
//     "Tell me what a Inventory Turnover Ratio report should be, expand on the information from Measures how often inventory is sold and replaced.\n\nDescribe the predictives listed in Forecasting future inventory needs based on sales trends. and explain why it is important, and how external factors listed in Seasonal demand fluctuations; supplier reliability. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Inventory Turnover Ratio\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Inventory_Management_KPI_Order_Accuracy_Rate:
//     "Tell me what a Order Accuracy Rate report should be, expand on the information from Percentage of orders delivered correctly.\n\nDescribe the predictives listed in Identifying trends for future logistic improvements. and explain why it is important, and how external factors listed in Quality of order fulfillment processes; supplier reliability. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Order Accuracy Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Inventory_Management_KPI_Stockout_Rate:
//     "Tell me what a Stockout Rate report should be, expand on the information from Frequency of inventory being depleted.\n\nDescribe the predictives listed in Predicting future stock levels based on historical stockout data. and explain why it is important, and how external factors listed in Supplier delays; changes in consumer demand. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Stockout Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sales_Management_Analytics_Customer_Segmentation_Analysis:
//     "Tell me what a Customer Segmentation Analysis report should be, expand on the information from Analyzes customer data to categorize them into distinct segments.\n\nDescribe the predictives listed in Guiding targeted marketing and sales strategies for specific customer segments. and explain why it is important, and how external factors listed in Demographics; buying behaviors. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Customer Segmentation Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sales_Management_Analytics_Lead_Source_Analysis:
//     "Tell me what a Lead Source Analysis report should be, expand on the information from Evaluates which lead sources contribute most to sales.\n\nDescribe the predictives listed in Evaluating marketing effectiveness and guiding resource allocation. and explain why it is important, and how external factors listed in Marketing campaign effectiveness; changes in lead generation methods. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Lead Source Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sales_Management_Analytics_Product_Performance_Analysis:
//     "Tell me what a Product Performance Analysis report should be, expand on the information from Tracks sales performance by product to identify bestsellers and underperformers.\n\nDescribe the predictives listed in Forecasting future production needs based on product trends. and explain why it is important, and how external factors listed in Market demand; seasonal influences. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Product Performance Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sales_Management_Analytics_Revenue_Forecasting:
//     "Tell me what a Revenue Forecasting report should be, expand on the information from Predicts future sales based on historical data and trends.\n\nDescribe the predictives listed in Guiding budget considerations and business strategy. and explain why it is important, and how external factors listed in Market trends; economic forecasts. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Revenue Forecasting\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sales_Management_Analytics_Sales_Funnel_Analysis:
//     "Tell me what a Sales Funnel Analysis report should be, expand on the information from Visualizes the stages prospects go through before becoming customers.\n\nDescribe the predictives listed in Identifying bottlenecks or drop-off points in the sales process. and explain why it is important, and how external factors listed in Changes in buyer behavior; market conditions. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Sales Funnel Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sales_Management_Analytics_Sales_Territory_Performance:
//     "Tell me what a Sales Territory Performance report should be, expand on the information from Analyzes performance of sales by geographic or market segments.\n\nDescribe the predictives listed in Guiding resource allocation for future sales strategies. and explain why it is important, and how external factors listed in Changes in territory demographics; competition levels. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Sales Territory Performance\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sales_Management_KPI_Average_Deal_Size:
//     "Tell me what a Average Deal Size report should be, expand on the information from Calculates average revenue generated per closed deal.\n\nDescribe the predictives listed in Forecasting future revenue based on deal size trends. and explain why it is important, and how external factors listed in Market demand; competitive pricing strategies. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Average Deal Size\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sales_Management_KPI_Conversion_Rate:
//     "Tell me what a Conversion Rate report should be, expand on the information from Percentage of leads that convert into actual sales.\n\nDescribe the predictives listed in Evaluating the effectiveness of the sales process and identifying areas for improvement. and explain why it is important, and how external factors listed in Quality of leads; effectiveness of sales pitches. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Conversion Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sales_Management_KPI_Sales_Cycle_Length:
//     "Tell me what a Sales Cycle Length report should be, expand on the information from Measures the average time taken to close a sale.\n\nDescribe the predictives listed in Predicting future sales forecasting based on historical sales cycle times. and explain why it is important, and how external factors listed in Sales process efficiency; customer decision-making speed. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Sales Cycle Length\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sales_Management_KPI_Sales_Growth_Rate:
//     "Tell me what a Sales Growth Rate report should be, expand on the information from Measures the percentage increase in sales over a specific period.\n\nDescribe the predictives listed in Forecasting future sales based on growth trends. and explain why it is important, and how external factors listed in Market conditions; overall economic environment. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Sales Growth Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sales_Management_KPI_Sales_Target_Achievement:
//     "Tell me what a Sales Target Achievement report should be, expand on the information from Compares actual sales to sales targets set for a period.\n\nDescribe the predictives listed in Assessing effectiveness of sales strategies based on target performance. and explain why it is important, and how external factors listed in Changes in market dynamics; sales team performance. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Sales Target Achievement\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sourcing_and_Procurement_Analytics_Contract_Compliance_Analysis:
//     "Tell me what a Contract Compliance Analysis report should be, expand on the information from Tracks adherence to contract terms by suppliers.\n\nDescribe the predictives listed in Forecasting areas of improvement based on compliance results. and explain why it is important, and how external factors listed in Supplier negotiation tactics; changing regulations. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Contract Compliance Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sourcing_and_Procurement_Analytics_Risk_Assessment_Analysis:
//     "Tell me what a Risk Assessment Analysis report should be, expand on the information from Evaluates risks associated with procurement decisions.\n\nDescribe the predictives listed in Improving supplier selection and risk mitigation strategies. and explain why it is important, and how external factors listed in Market volatility; geopolitical issues. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Risk Assessment Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sourcing_and_Procurement_Analytics_Spend_Analysis:
//     "Tell me what a Spend Analysis report should be, expand on the information from Analyzes spending patterns to identify cost-saving opportunities.\n\nDescribe the predictives listed in Guiding budgeting and forecasting strategies based on spending history. and explain why it is important, and how external factors listed in Economic factors; pricing trends in the market. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Spend Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sourcing_and_Procurement_Analytics_Supplier_Performance_Analysis:
//     "Tell me what a Supplier Performance Analysis report should be, expand on the information from Evaluates supplier reliability and compliance with contract terms.\n\nDescribe the predictives listed in Identifying reliable suppliers for negotiation leverage. and explain why it is important, and how external factors listed in Supplier market conditions; performance tracking. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Supplier Performance Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sourcing_and_Procurement_Analytics_Supplier_Relationship_Management:
//     "Tell me what a Supplier Relationship Management report should be, expand on the information from Analyzes relationships and effectiveness with vendors.\n\nDescribe the predictives listed in Forecasting potential negotiation opportunities based on relationship strength. and explain why it is important, and how external factors listed in Supplier dynamics; long-term relationships. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Supplier Relationship Management\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sourcing_and_Procurement_KPI_Cost_Reduction_Percentage:
//     "Tell me what a Cost Reduction Percentage report should be, expand on the information from Measures savings achieved compared to previous periods or quoted prices.\n\nDescribe the predictives listed in Evaluating future cost-saving strategies based on historical data. and explain why it is important, and how external factors listed in Market price fluctuations; supplier competition. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Cost Reduction Percentage\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sourcing_and_Procurement_KPI_Order_Accuracy_Rate:
//     "Tell me what a Order Accuracy Rate report should be, expand on the information from Percentage of purchase orders delivered correctly.\n\nDescribe the predictives listed in Identifying trends for improving ordering processes. and explain why it is important, and how external factors listed in Quality of supplier operations; clarity in order specifications. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Order Accuracy Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sourcing_and_Procurement_KPI_Purchase_Order_Cycle_Time:
//     "Tell me what a Purchase Order Cycle Time report should be, expand on the information from Average time from creating to fulfilling a purchase order.\n\nDescribe the predictives listed in Forecasting potential delays in supply chain management. and explain why it is important, and how external factors listed in Supplier reliability; lead times. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Purchase Order Cycle Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sourcing_and_Procurement_KPI_Spend_Under_Management:
//     "Tell me what a Spend Under Management report should be, expand on the information from Proportion of total spend managed by procurement.\n\nDescribe the predictives listed in Assessing effectiveness of procurement strategies. and explain why it is important, and how external factors listed in Organizational spending policies; market competition. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Spend Under Management\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
//   Explain_KPI_Sourcing_and_Procurement_KPI_Supplier_Lead_Time:
//     "Tell me what a Supplier Lead Time report should be, expand on the information from Average time taken by suppliers to deliver goods.\n\nDescribe the predictives listed in Evaluating need for strategic sourcing improvements. and explain why it is important, and how external factors listed in Supplier efficiency; market conditions. and any others affect this goal. Required sections:\n- Summary\n- Key Information about \n   - Name Supplier Lead Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n",
// };

export const prompts = {
  Create_HTML_Accounts_Payable_Analytics_Aging_of_Receivables:
    'Please create a visualization for the Aging of Receivables Analytics.\n\nThis is a Accounts Payable metric that Breakdown of outstanding receivables by age.\n\nThis visualization will use the attached data files to create the Aging Chart; Heatmap.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_Analytics_Collection_Effectiveness_Index:
    'Please create a visualization for the Collection Effectiveness Index Analytics.\n\nThis is a Accounts Payable metric that Evaluates the effectiveness of the collections process.\n\nThis visualization will use the attached data files to create the Bar Chart; Line Chart. The calculation should follow this formula: [(Total Receivables - Ending Receivables) / Total Receivables] x 100Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_Analytics_Cost_per_Invoice:
    'Please create a visualization for the Cost per Invoice Analytics.\n\nThis is a Accounts Payable metric that Processing cost per invoice; identifies cost reduction opportunities.\n\nThis visualization will use the attached data files to create the Bar Chart; Pie Chart. The calculation should follow this formula: Total Processing Costs / Number of InvoicesRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_Analytics_Invoice_Processing_Time:
    'Please create a visualization for the Invoice Processing Time Analytics.\n\nThis is a Accounts Payable metric that Average time taken to process invoices.\n\nThis visualization will use the attached data files to create the Line Chart; Histogram. The calculation should follow this formula: Total Processing Time / Number of InvoicesRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_Analytics_Payment_Trends:
    'Please create a visualization for the Payment Trends Analytics.\n\nThis is a Accounts Payable metric that Analyzes payment patterns and behaviors.\n\nThis visualization will use the attached data files to create the Line Chart; Time Series Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_Analytics_Supplier_Analysis:
    'Please create a visualization for the Supplier Analysis Analytics.\n\nThis is a Accounts Payable metric that Analysis of key supplier metrics.\n\nThis visualization will use the attached data files to create the Spider Chart; Table Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_Analytics_Variance_Analysis:
    'Please create a visualization for the Variance Analysis Analytics.\n\nThis is a Accounts Payable metric that Compares expected vs actual spending; monitors discrepancies.\n\nThis visualization will use the attached data files to create the Line Chart; Bar Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_KPI_Aging_Reports:
    'Please create a visualization for the Aging Reports KPI.\n\nThis is a Accounts Payable metric that Breakdown of accounts payable by age; identifies overdue payments.\n\nThis visualization will use the attached data files to create the Aging Chart; Heatmap.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_KPI_Cost_per_Invoice:
    'Please create a visualization for the Cost per Invoice KPI.\n\nThis is a Accounts Payable metric that Average cost of processing each invoice.\n\nThis visualization will use the attached data files to create the Bar Chart; Pie Chart. The calculation should follow this formula: Total Processing Costs / Number of InvoicesRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_KPI_Days_Payable_Outstanding_DPO:
    'Please create a visualization for the Days Payable Outstanding (DPO) KPI.\n\nThis is a Accounts Payable metric that Measures average number of days to pay invoices.\n\nThis visualization will use the attached data files to create the Line Chart; Bar Chart. The calculation should follow this formula: [(Accounts Payable / Cost of Goods Sold) x 365]Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_KPI_Early_Payment_Discounts_Captured:
    'Please create a visualization for the Early Payment Discounts Captured KPI.\n\nThis is a Accounts Payable metric that Evaluates percentage of early discounts utilized versus available.\n\nThis visualization will use the attached data files to create the Bar Chart; Funnel Chart. The calculation should follow this formula: [(Discounts Taken / Discounts Offered) x 100]Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_KPI_Invoice_Exception_Rate:
    'Please create a visualization for the Invoice Exception Rate KPI.\n\nThis is a Accounts Payable metric that Percentage of invoices needing manual intervention due to discrepancies.\n\nThis visualization will use the attached data files to create the Pie Chart; Bar Chart. The calculation should follow this formula: [(Invoices Requiring Intervention / Total Invoices) x 100]Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_KPI_Invoice_Processing_Time:
    'Please create a visualization for the Invoice Processing Time KPI.\n\nThis is a Accounts Payable metric that Measures time taken to process each invoice from receipt to payment.\n\nThis visualization will use the attached data files to create the Line Chart; Histogram. The calculation should follow this formula: Total Processing Time / Number of InvoicesRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_KPI_Late_Payment_Penalties:
    'Please create a visualization for the Late Payment Penalties KPI.\n\nThis is a Accounts Payable metric that Tracks costs incurred from late payments.\n\nThis visualization will use the attached data files to create the Column Chart; Line Chart. The calculation should follow this formula: Total Late Payment Penalties / Total InvoicesRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_KPI_Payment_Accuracy_Rate:
    'Please create a visualization for the Payment Accuracy Rate KPI.\n\nThis is a Accounts Payable metric that Percentage of payments made accurately without errors.\n\nThis visualization will use the attached data files to create the Gauge; Pie Chart. The calculation should follow this formula: [(Accurate Payments / Total Payments) x 100]Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_KPI_Spending_Analysis_by_Category:
    'Please create a visualization for the Spending Analysis by Category KPI.\n\nThis is a Accounts Payable metric that Tracks spending across categories to identify trends and savings.\n\nThis visualization will use the attached data files to create the Stacked Bar Chart; Pie Chart. The calculation should follow this formula: Spend by Category / Total SpendRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Payable_KPI_Supplier_Payment_Terms:
    'Please create a visualization for the Supplier Payment Terms KPI.\n\nThis is a Accounts Payable metric that Analyzes agreed payment terms with suppliers and tracks compliance.\n\nThis visualization will use the attached data files to create the Gantt Chart; Table Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Receivable_Analytics_Aging_of_Receivables:
    'Please create a visualization for the Aging of Receivables Analytics.\n\nThis is a Accounts Receivable metric that Breakdown of outstanding receivables by age to identify collection issues.\n\nThis visualization will use the attached data files to create the Aging Chart; Heatmap.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Receivable_Analytics_Cash_Conversion_Cycle_CCC:
    'Please create a visualization for the Cash Conversion Cycle (CCC) Analytics.\n\nThis is a Accounts Receivable metric that Analyzes time taken to convert resource investments into cash flows.\n\nThis visualization will use the attached data files to create the Line Chart; Funnel Chart. The calculation should follow this formula: (Days Sales Outstanding + Days Inventory Outstanding - Days Payable Outstanding)Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Receivable_Analytics_Collection_Effectiveness_Index:
    'Please create a visualization for the Collection Effectiveness Index Analytics.\n\nThis is a Accounts Receivable metric that Evaluates the effectiveness of the collections process.\n\nThis visualization will use the attached data files to create the Bar Chart; Line Chart. The calculation should follow this formula: [(Total Receivables - Ending Total Receivables) / Total Receivables] x 100Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Receivable_Analytics_Credit_Risk_Analysis:
    'Please create a visualization for the Credit Risk Analysis Analytics.\n\nThis is a Accounts Receivable metric that Assesses risk associated with extending credit to customers.\n\nThis visualization will use the attached data files to create the Spider Chart; Risk Matrix.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Receivable_Analytics_Customer_Payment_Trends:
    'Please create a visualization for the Customer Payment Trends Analytics.\n\nThis is a Accounts Receivable metric that Analyzes payment behaviors and patterns of customers.\n\nThis visualization will use the attached data files to create the Line Chart; Time Series Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Receivable_Analytics_Dispute_Resolution_Time:
    'Please create a visualization for the Dispute Resolution Time Analytics.\n\nThis is a Accounts Receivable metric that Tracks the time taken to resolve billing disputes with customers.\n\nThis visualization will use the attached data files to create the Gantt Chart; Column Chart. The calculation should follow this formula: Total Dispute Resolution Time / Number of DisputesRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Receivable_KPI_Average_Days_Delinquent_ADD:
    'Please create a visualization for the Average Days Delinquent (ADD) KPI.\n\nThis is a Accounts Receivable metric that Average number of days payments are past due.\n\nThis visualization will use the attached data files to create the Line Chart; Column Chart. The calculation should follow this formula: Total Days Past Due / Number of Delinquent InvoicesRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Receivable_KPI_Bad_Debt_to_Sales_Ratio:
    'Please create a visualization for the Bad Debt to Sales Ratio KPI.\n\nThis is a Accounts Receivable metric that Proportion of account receivables written off as bad debt.\n\nThis visualization will use the attached data files to create the Bar Chart; Pie Chart. The calculation should follow this formula: (Bad Debt / Total Sales)Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Receivable_KPI_Days_Sales_Outstanding_DSO:
    'Please create a visualization for the Days Sales Outstanding (DSO) KPI.\n\nThis is a Accounts Receivable metric that Measures average days to collect payment after sales.\n\nThis visualization will use the attached data files to create the Line Chart; Bar Chart. The calculation should follow this formula: [(Accounts Receivable / Net Credit Sales) x 365]Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Receivable_KPI_Invoice_Dispute_Rate:
    'Please create a visualization for the Invoice Dispute Rate KPI.\n\nThis is a Accounts Receivable metric that Percentage of invoices disputed by customers.\n\nThis visualization will use the attached data files to create the Pie Chart; Bar Chart. The calculation should follow this formula: [(Disputed Invoices / Total Invoices) x 100]Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Accounts_Receivable_KPI_Receivables_Turnover_Ratio:
    'Please create a visualization for the Receivables Turnover Ratio KPI.\n\nThis is a Accounts Receivable metric that Measures effectiveness of using receivables for generating sales.\n\nThis visualization will use the attached data files to create the Column Chart; Line Chart. The calculation should follow this formula: (Net Credit Sales / Average Accounts Receivable)Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Asset_Management_Analytics_Asset_Condition_Assessment:
    'Please create a visualization for the Asset Condition Assessment Analytics.\n\nThis is a Asset Management metric that Evaluates the current condition of assets to inform maintenance decisions.\n\nThis visualization will use the attached data files to create the Heatmap; Radar Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Asset_Management_Analytics_Compliance_and_Risk_Management:
    'Please create a visualization for the Compliance and Risk Management Analytics.\n\nThis is a Asset Management metric that Tracks adherence to regulations and identifies risks associated with asset management.\n\nThis visualization will use the attached data files to create the Risk Matrix; Bar Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Asset_Management_Analytics_Depreciation_Tracking:
    'Please create a visualization for the Depreciation Tracking Analytics.\n\nThis is a Asset Management metric that Monitors the depreciation of assets over time.\n\nThis visualization will use the attached data files to create the Line Chart; Area Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Asset_Management_Analytics_Maintenance_Frequency_Analysis:
    'Please create a visualization for the Maintenance Frequency Analysis Analytics.\n\nThis is a Asset Management metric that Analyzes the frequency of asset maintenance activities.\n\nThis visualization will use the attached data files to create the Line Chart; Histogram.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Asset_Management_Analytics_Replacement_Cost_Analysis:
    'Please create a visualization for the Replacement Cost Analysis Analytics.\n\nThis is a Asset Management metric that Calculates the cost to replace an asset at current market rates.\n\nThis visualization will use the attached data files to create the Bar Chart; Line Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Asset_Management_KPI_Asset_Age:
    'Please create a visualization for the Asset Age KPI.\n\nThis is a Asset Management metric that Tracks the average age of assets to assess potential obsolescence.\n\nThis visualization will use the attached data files to create the Line Chart; Bar Chart. The calculation should follow this formula: Total Age of Assets / Total Number of AssetsRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Asset_Management_KPI_Asset_Disposal_Rate:
    'Please create a visualization for the Asset Disposal Rate KPI.\n\nThis is a Asset Management metric that Measures the efficiency of disposing of assets that have reached end-of-life.\n\nThis visualization will use the attached data files to create the Bar Chart; Line Chart. The calculation should follow this formula: (Total Assets Disposed / Total Assets)Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Asset_Management_KPI_Asset_Utilization_Rate:
    'Please create a visualization for the Asset Utilization Rate KPI.\n\nThis is a Asset Management metric that Measures efficiency of utilizing assets to generate revenue.\n\nThis visualization will use the attached data files to create the Gauge; Line Chart. The calculation should follow this formula: Total Revenue / Total AssetsRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Asset_Management_KPI_Maintenance_Cost_as_a_Percentage_of_Asset_Value:
    'Please create a visualization for the Maintenance Cost as a Percentage of Asset Value KPI.\n\nThis is a Asset Management metric that Assesses costs associated with maintaining assets.\n\nThis visualization will use the attached data files to create the Bar Chart; Pie Chart. The calculation should follow this formula: (Total Maintenance Costs / Total Asset Value) x 100Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Asset_Management_KPI_Return_on_Assets_ROA:
    'Please create a visualization for the Return on Assets (ROA) KPI.\n\nThis is a Asset Management metric that Measures profitability in relation to total assets.\n\nThis visualization will use the attached data files to create the Bar Chart; Line Chart. The calculation should follow this formula: Net Income / Total AssetsRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Cash_Bank_Management_Analytics_Bank_Fees_Analysis:
    'Please create a visualization for the Bank Fees Analysis Analytics.\n\nThis is a Cash & Bank Management metric that Analyzes fees incurred from banking transactions to identify cost-saving opportunities.\n\nThis visualization will use the attached data files to create the Bar Chart; Column Chart. The calculation should follow this formula: Total Bank Fees / Total TransactionsRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Cash_Bank_Management_Analytics_Cash_Conversion_Cycle_CCC:
    'Please create a visualization for the Cash Conversion Cycle (CCC) Analytics.\n\nThis is a Cash & Bank Management metric that Analyzes the time taken to convert resource investments into cash flows.\n\nThis visualization will use the attached data files to create the Line Chart; Funnel Chart. The calculation should follow this formula: (Days Sales Outstanding + Days Inventory Outstanding - Days Payable Outstanding)Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Cash_Bank_Management_Analytics_Cash_Flow_Variance_Analysis:
    'Please create a visualization for the Cash Flow Variance Analysis Analytics.\n\nThis is a Cash & Bank Management metric that Compares projected vs actual cash flows to assess discrepancies.\n\nThis visualization will use the attached data files to create the Line Chart; Waterfall Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Cash_Bank_Management_Analytics_Fraud_Detection_and_Prevention:
    'Please create a visualization for the Fraud Detection and Prevention Analytics.\n\nThis is a Cash & Bank Management metric that Monitors and analyzes data to identify and prevent fraudulent activities.\n\nThis visualization will use the attached data files to create the Heatmap; Scatter Plot.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Cash_Bank_Management_Analytics_Investment_Income:
    'Please create a visualization for the Investment Income Analytics.\n\nThis is a Cash & Bank Management metric that Measures the returns generated from the company\'s short-term investments.\n\nThis visualization will use the attached data files to create the Line Chart; Pie Chart. The calculation should follow this formula: Total Investment IncomeRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Cash_Bank_Management_KPI_Bank_Reconciliation_Time:
    'Please create a visualization for the Bank Reconciliation Time KPI.\n\nThis is a Cash & Bank Management metric that Tracks the time taken to complete bank reconciliations.\n\nThis visualization will use the attached data files to create the Line Chart; Histogram. The calculation should follow this formula: Total Reconciliation Time / Number of ReconciliationsRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Cash_Bank_Management_KPI_Cash_Flow_Forecasting_Accuracy:
    'Please create a visualization for the Cash Flow Forecasting Accuracy KPI.\n\nThis is a Cash & Bank Management metric that Measures the precision of cash flow predictions compared to actual cash flows.\n\nThis visualization will use the attached data files to create the Line Chart; Bar Chart. The calculation should follow this formula: (Forecasted Cash Flow - Actual Cash Flow) / Forecasted Cash Flow x 100Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Cash_Bank_Management_KPI_Days_Cash_on_Hand:
    'Please create a visualization for the Days Cash on Hand KPI.\n\nThis is a Cash & Bank Management metric that Indicates the number of days the company can pay its expenses with current cash.\n\nThis visualization will use the attached data files to create the Line Chart; Area Chart. The calculation should follow this formula: Cash / Daily Operating ExpensesRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Cash_Bank_Management_KPI_Interest_Income_Expense_Analysis:
    'Please create a visualization for the Interest Income/Expense Analysis KPI.\n\nThis is a Cash & Bank Management metric that Assesses the income generated from interest or costs incurred from interest on debts.\n\nThis visualization will use the attached data files to create the Bar Chart; Stacked Bar Chart. The calculation should follow this formula: (Total Interest Income - Total Interest Expenses)Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Cash_Bank_Management_KPI_Liquidity_Ratio:
    'Please create a visualization for the Liquidity Ratio KPI.\n\nThis is a Cash & Bank Management metric that Evaluates the company\'s ability to meet short-term liabilities with liquid assets.\n\nThis visualization will use the attached data files to create the Gauge; Bar Chart. The calculation should follow this formula: Current Assets / Current LiabilitiesRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_General_Ledger_Analytics_Data_Integrity:
    'Please create a visualization for the Data Integrity Analytics.\n\nThis is a General Ledger metric that Assesses the accuracy and completeness of data entered.\n\nThis visualization will use the attached data files to create the Line Chart; Histogram.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_General_Ledger_Analytics_Intercompany_Reconciliation_Time:
    'Please create a visualization for the Intercompany Reconciliation Time Analytics.\n\nThis is a General Ledger metric that Measures the time required to reconcile intercompany transactions.\n\nThis visualization will use the attached data files to create the Line Chart; Bar Chart. The calculation should follow this formula: Total Intercompany Reconciliation Time / Number of TransactionsRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_General_Ledger_Analytics_Internal_Audit_Findings:
    'Please create a visualization for the Internal Audit Findings Analytics.\n\nThis is a General Ledger metric that Tracks the number and significance of issues identified during internal audits.\n\nThis visualization will use the attached data files to create the Bar Chart; Column Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_General_Ledger_Analytics_Regulatory_Compliance:
    'Please create a visualization for the Regulatory Compliance Analytics.\n\nThis is a General Ledger metric that Monitors adherence to financial regulations and standards.\n\nThis visualization will use the attached data files to create the Gauge; Line Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_General_Ledger_Analytics_Variance_Analysis:
    'Please create a visualization for the Variance Analysis Analytics.\n\nThis is a General Ledger metric that Compares expected versus actual figures to identify anomalies.\n\nThis visualization will use the attached data files to create the Line Chart; Waterfall Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_General_Ledger_KPI_Accuracy_of_Financial_Statements:
    'Please create a visualization for the Accuracy of Financial Statements KPI.\n\nThis is a General Ledger metric that Evaluates the correctness of reported financial data.\n\nThis visualization will use the attached data files to create the Gauge; Pie Chart. The calculation should follow this formula: (Number of Accurate Reports / Total Reports) x 100Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_General_Ledger_KPI_Balance_Sheet_Reconciliation_Time:
    'Please create a visualization for the Balance Sheet Reconciliation Time KPI.\n\nThis is a General Ledger metric that Tracks the time taken to reconcile balance sheet accounts.\n\nThis visualization will use the attached data files to create the Line Chart; Histogram. The calculation should follow this formula: Total Reconciliation Time / Number of AccountsRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_General_Ledger_KPI_Chart_of_Accounts_Utilization:
    'Please create a visualization for the Chart of Accounts Utilization KPI.\n\nThis is a General Ledger metric that Analyzes how effectively the chart of accounts is used.\n\nThis visualization will use the attached data files to create the Table Chart; Heatmap.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_General_Ledger_KPI_Financial_Close_Time:
    'Please create a visualization for the Financial Close Time KPI.\n\nThis is a General Ledger metric that Measures the time taken to close the books at the end of a financial period.\n\nThis visualization will use the attached data files to create the Line Chart; Bar Chart. The calculation should follow this formula: (End of Close Date - Start of Closing Process)Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_General_Ledger_KPI_Journal_Entry_Error_Rate:
    'Please create a visualization for the Journal Entry Error Rate KPI.\n\nThis is a General Ledger metric that Calculates the percentage of incorrect journal entries that require adjustment.\n\nThis visualization will use the attached data files to create the Bar Chart; Column Chart. The calculation should follow this formula: (Number of Incorrect Entries / Total Journal Entries) x 100Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Inventory_Management_Analytics_Demand_Variability_Analysis:
    'Please create a visualization for the Demand Variability Analysis Analytics.\n\nThis is a Inventory Management metric that Tracks fluctuations in consumer demand over time.\n\nThis visualization will use the attached data files to create the Line Chart; Bar Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Inventory_Management_Analytics_Inventory_Valuation:
    'Please create a visualization for the Inventory Valuation Analytics.\n\nThis is a Inventory Management metric that Assessing the value of all stock based on accounting methods.\n\nThis visualization will use the attached data files to create the Bar Chart; Line Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Inventory_Management_Analytics_Reorder_Point_Analysis:
    'Please create a visualization for the Reorder Point Analysis Analytics.\n\nThis is a Inventory Management metric that Calculates minimum stock level before reordering.\n\nThis visualization will use the attached data files to create the Line Chart; Gantt Chart. The calculation should follow this formula: (Lead Time Demand + Safety Stock)Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Inventory_Management_Analytics_Sales_Forecasting:
    'Please create a visualization for the Sales Forecasting Analytics.\n\nThis is a Inventory Management metric that Predicts future product sales based on historical data and trends.\n\nThis visualization will use the attached data files to create the Line Chart; Area Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Inventory_Management_Analytics_Stock_Aging_Analysis:
    'Please create a visualization for the Stock Aging Analysis Analytics.\n\nThis is a Inventory Management metric that Breakdown of inventory age to identify slow-moving items.\n\nThis visualization will use the attached data files to create the Aging Chart; Heatmap.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Inventory_Management_KPI_Carrying_Cost_of_Inventory:
    'Please create a visualization for the Carrying Cost of Inventory KPI.\n\nThis is a Inventory Management metric that Total cost of storing and managing inventory.\n\nThis visualization will use the attached data files to create the Bar Chart; Pie Chart. The calculation should follow this formula: (Carrying Costs / Average Inventory)Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Inventory_Management_KPI_Days_Inventory_Outstanding_DIO:
    'Please create a visualization for the Days Inventory Outstanding (DIO) KPI.\n\nThis is a Inventory Management metric that Average number of days inventory is held before being sold.\n\nThis visualization will use the attached data files to create the Line Chart; Bar Chart. The calculation should follow this formula: (365 / Inventory Turnover Ratio)Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Inventory_Management_KPI_Inventory_Turnover_Ratio:
    'Please create a visualization for the Inventory Turnover Ratio KPI.\n\nThis is a Inventory Management metric that Measures how often inventory is sold and replaced.\n\nThis visualization will use the attached data files to create the Line Chart; Bar Chart. The calculation should follow this formula: (Cost of Goods Sold / Average Inventory)Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Inventory_Management_KPI_Order_Accuracy_Rate:
    'Please create a visualization for the Order Accuracy Rate KPI.\n\nThis is a Inventory Management metric that Percentage of orders delivered correctly.\n\nThis visualization will use the attached data files to create the Gauge; Pie Chart. The calculation should follow this formula: (Accurate Orders / Total Orders) x 100Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Inventory_Management_KPI_Stockout_Rate:
    'Please create a visualization for the Stockout Rate KPI.\n\nThis is a Inventory Management metric that Frequency of inventory being depleted.\n\nThis visualization will use the attached data files to create the Bar Chart; Line Chart. The calculation should follow this formula: (Stockouts / Total Inventory Demands)Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sales_Management_Analytics_Customer_Segmentation_Analysis:
    'Please create a visualization for the Customer Segmentation Analysis Analytics.\n\nThis is a Sales Management metric that Analyzes customer data to categorize them into distinct segments.\n\nThis visualization will use the attached data files to create the Pie Chart; Treemap.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sales_Management_Analytics_Lead_Source_Analysis:
    'Please create a visualization for the Lead Source Analysis Analytics.\n\nThis is a Sales Management metric that Evaluates which lead sources contribute most to sales.\n\nThis visualization will use the attached data files to create the Bar Chart; Pie Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sales_Management_Analytics_Product_Performance_Analysis:
    'Please create a visualization for the Product Performance Analysis Analytics.\n\nThis is a Sales Management metric that Tracks sales performance by product to identify bestsellers and underperformers.\n\nThis visualization will use the attached data files to create the Bar Chart; Stacked Bar Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sales_Management_Analytics_Revenue_Forecasting:
    'Please create a visualization for the Revenue Forecasting Analytics.\n\nThis is a Sales Management metric that Predicts future sales based on historical data and trends.\n\nThis visualization will use the attached data files to create the Line Chart; Area Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sales_Management_Analytics_Sales_Funnel_Analysis:
    'Please create a visualization for the Sales Funnel Analysis Analytics.\n\nThis is a Sales Management metric that Visualizes the stages prospects go through before becoming customers.\n\nThis visualization will use the attached data files to create the Funnel Chart; Sankey Diagram.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sales_Management_Analytics_Sales_Territory_Performance:
    'Please create a visualization for the Sales Territory Performance Analytics.\n\nThis is a Sales Management metric that Analyzes performance of sales by geographic or market segments.\n\nThis visualization will use the attached data files to create the Geographic Map; Heatmap.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sales_Management_KPI_Average_Deal_Size:
    'Please create a visualization for the Average Deal Size KPI.\n\nThis is a Sales Management metric that Calculates average revenue generated per closed deal.\n\nThis visualization will use the attached data files to create the Bar Chart; Pie Chart. The calculation should follow this formula: Total Revenue / Number of DealsRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sales_Management_KPI_Conversion_Rate:
    'Please create a visualization for the Conversion Rate KPI.\n\nThis is a Sales Management metric that Percentage of leads that convert into actual sales.\n\nThis visualization will use the attached data files to create the Funnel Chart; Line Chart. The calculation should follow this formula: (Number of Closed Sales / Number of Leads) x 100Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sales_Management_KPI_Sales_Cycle_Length:
    'Please create a visualization for the Sales Cycle Length KPI.\n\nThis is a Sales Management metric that Measures the average time taken to close a sale.\n\nThis visualization will use the attached data files to create the Gantt Chart; Histogram. The calculation should follow this formula: Total Sales Cycle Duration / Number of DealsRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sales_Management_KPI_Sales_Growth_Rate:
    'Please create a visualization for the Sales Growth Rate KPI.\n\nThis is a Sales Management metric that Measures the percentage increase in sales over a specific period.\n\nThis visualization will use the attached data files to create the Line Chart; Bar Chart. The calculation should follow this formula: [(Current Period Sales - Previous Period Sales) / Previous Period Sales] x 100Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sales_Management_KPI_Sales_Target_Achievement:
    'Please create a visualization for the Sales Target Achievement KPI.\n\nThis is a Sales Management metric that Compares actual sales to sales targets set for a period.\n\nThis visualization will use the attached data files to create the Gauge Chart; Column Chart. The calculation should follow this formula: (Actual Sales / Sales Target) x 100Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sourcing_and_Procurement_Analytics_Contract_Compliance_Analysis:
    'Please create a visualization for the Contract Compliance Analysis Analytics.\n\nThis is a Sourcing and Procurement metric that Tracks adherence to contract terms by suppliers.\n\nThis visualization will use the attached data files to create the Bar Chart; Line Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sourcing_and_Procurement_Analytics_Risk_Assessment_Analysis:
    'Please create a visualization for the Risk Assessment Analysis Analytics.\n\nThis is a Sourcing and Procurement metric that Evaluates risks associated with procurement decisions.\n\nThis visualization will use the attached data files to create the Heatmap; Risk Matrix.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sourcing_and_Procurement_Analytics_Spend_Analysis:
    'Please create a visualization for the Spend Analysis Analytics.\n\nThis is a Sourcing and Procurement metric that Analyzes spending patterns to identify cost-saving opportunities.\n\nThis visualization will use the attached data files to create the Stacked Bar Chart; Pie Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sourcing_and_Procurement_Analytics_Supplier_Performance_Analysis:
    'Please create a visualization for the Supplier Performance Analysis Analytics.\n\nThis is a Sourcing and Procurement metric that Evaluates supplier reliability and compliance with contract terms.\n\nThis visualization will use the attached data files to create the Radar Chart; Bar Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sourcing_and_Procurement_Analytics_Supplier_Relationship_Management:
    'Please create a visualization for the Supplier Relationship Management Analytics.\n\nThis is a Sourcing and Procurement metric that Analyzes relationships and effectiveness with vendors.\n\nThis visualization will use the attached data files to create the Spider Chart; Table Chart.Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sourcing_and_Procurement_KPI_Cost_Reduction_Percentage:
    'Please create a visualization for the Cost Reduction Percentage KPI.\n\nThis is a Sourcing and Procurement metric that Measures savings achieved compared to previous periods or quoted prices.\n\nThis visualization will use the attached data files to create the Bar Chart; Line Chart. The calculation should follow this formula: [(Previous Cost - Current Cost) / Previous Cost] x 100Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sourcing_and_Procurement_KPI_Order_Accuracy_Rate:
    'Please create a visualization for the Order Accuracy Rate KPI.\n\nThis is a Sourcing and Procurement metric that Percentage of purchase orders delivered correctly.\n\nThis visualization will use the attached data files to create the Gauge; Pie Chart. The calculation should follow this formula: (Accurate Orders / Total Orders) x 100Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sourcing_and_Procurement_KPI_Purchase_Order_Cycle_Time:
    'Please create a visualization for the Purchase Order Cycle Time KPI.\n\nThis is a Sourcing and Procurement metric that Average time from creating to fulfilling a purchase order.\n\nThis visualization will use the attached data files to create the Gantt Chart; Line Chart. The calculation should follow this formula: Total Time to Complete Orders / Number of OrdersRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sourcing_and_Procurement_KPI_Spend_Under_Management:
    'Please create a visualization for the Spend Under Management KPI.\n\nThis is a Sourcing and Procurement metric that Proportion of total spend managed by procurement.\n\nThis visualization will use the attached data files to create the Pie Chart; Bar Chart. The calculation should follow this formula: (Managed Spend / Total Spend) x 100Required sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Create_HTML_Sourcing_and_Procurement_KPI_Supplier_Lead_Time:
    'Please create a visualization for the Supplier Lead Time KPI.\n\nThis is a Sourcing and Procurement metric that Average time taken by suppliers to deliver goods.\n\nThis visualization will use the attached data files to create the Line Chart; Bar Chart. The calculation should follow this formula: Total Supplier Lead Time / Number of OrdersRequired sections:\n- Summary\n- Key Findings\n- Data Visualizations (using Chart.js)',
  Explain_KPI_Accounts_Payable_Analytics_Aging_of_Receivables:
    "Tell me what a Aging of Receivables report should be, expand on the information from Breakdown of outstanding receivables by age.\n\nDescribe the predictives listed in Predicting collections based on aging trends. and explain why it is important, and how external factors listed in Changes in customer payment behavior; economic factors. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Aging of Receivables\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_Analytics_Collection_Effectiveness_Index:
    "Tell me what a Collection Effectiveness Index report should be, expand on the information from Evaluates the effectiveness of the collections process.\n\nDescribe the predictives listed in Forecasting future collection efforts based on historical data. and explain why it is important, and how external factors listed in Market conditions; customer financial health. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Collection Effectiveness Index\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_Analytics_Cost_per_Invoice:
    "Tell me what a Cost per Invoice report should be, expand on the information from Processing cost per invoice; identifies cost reduction opportunities.\n\nDescribe the predictives listed in Projecting potential savings from automation. and explain why it is important, and how external factors listed in Technological advancements; invoice volume. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Cost per Invoice\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_Analytics_Invoice_Processing_Time:
    "Tell me what a Invoice Processing Time report should be, expand on the information from Average time taken to process invoices.\n\nDescribe the predictives listed in Analyzing process efficiency to enhance payment cycles. and explain why it is important, and how external factors listed in Employee efficiency; technology systems. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Invoice Processing Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_Analytics_Payment_Trends:
    "Tell me what a Payment Trends report should be, expand on the information from Analyzes payment patterns and behaviors.\n\nDescribe the predictives listed in Identifying trends for future cash flow predictions. and explain why it is important, and how external factors listed in Changes in supplier payment terms; discounts offered. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Payment Trends\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_Analytics_Supplier_Analysis:
    "Tell me what a Supplier Analysis report should be, expand on the information from Analysis of key supplier metrics.\n\nDescribe the predictives listed in Identifying reliable suppliers for negotiation leverage. and explain why it is important, and how external factors listed in Industry standards; supplier market conditions. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Supplier Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_Analytics_Variance_Analysis:
    "Tell me what a Variance Analysis report should be, expand on the information from Compares expected vs actual spending; monitors discrepancies.\n\nDescribe the predictives listed in Identifying discrepancies for enhanced financial planning. and explain why it is important, and how external factors listed in Economic conditions impacting supplier pricing. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Variance Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_KPI_Aging_Reports:
    "Tell me what a Aging Reports report should be, expand on the information from Breakdown of accounts payable by age; identifies overdue payments.\n\nDescribe the predictives listed in Projected cash outflows based on outstanding liabilities. and explain why it is important, and how external factors listed in Supplier payment terms; cash flow position. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Aging Reports\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_KPI_Cost_per_Invoice:
    "Tell me what a Cost per Invoice report should be, expand on the information from Average cost of processing each invoice.\n\nDescribe the predictives listed in Analyzing cost trends for efficiencies through automation. and explain why it is important, and how external factors listed in Labor costs; technology used for processing invoices. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Cost per Invoice\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_KPI_Days_Payable_Outstanding_DPO:
    "Tell me what a Days Payable Outstanding (DPO) report should be, expand on the information from Measures average number of days to pay invoices.\n\nDescribe the predictives listed in Forecasting future liquidity based on payment trends. and explain why it is important, and how external factors listed in Payment terms negotiated with suppliers; overall cash flow. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Days Payable Outstanding (DPO)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_KPI_Early_Payment_Discounts_Captured:
    "Tell me what a Early Payment Discounts Captured report should be, expand on the information from Evaluates percentage of early discounts utilized versus available.\n\nDescribe the predictives listed in Assessing potential savings based on current relationships. and explain why it is important, and how external factors listed in Supplier willingness to offer discounts; available funds. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Early Payment Discounts Captured\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_KPI_Invoice_Exception_Rate:
    "Tell me what a Invoice Exception Rate report should be, expand on the information from Percentage of invoices needing manual intervention due to discrepancies.\n\nDescribe the predictives listed in Identifying process improvements to reduce exceptions. and explain why it is important, and how external factors listed in Data entry issues; supplier invoicing practices. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Invoice Exception Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_KPI_Invoice_Processing_Time:
    "Tell me what a Invoice Processing Time report should be, expand on the information from Measures time taken to process each invoice from receipt to payment.\n\nDescribe the predictives listed in Analysis of efficiency to enhance payment cycles. and explain why it is important, and how external factors listed in Workload fluctuations; employee efficiency. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Invoice Processing Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_KPI_Late_Payment_Penalties:
    "Tell me what a Late Payment Penalties report should be, expand on the information from Tracks costs incurred from late payments.\n\nDescribe the predictives listed in Monitoring historical penalty trends for future forecasts. and explain why it is important, and how external factors listed in Changes in payment processing systems; invoice accuracy. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Late Payment Penalties\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_KPI_Payment_Accuracy_Rate:
    "Tell me what a Payment Accuracy Rate report should be, expand on the information from Percentage of payments made accurately without errors.\n\nDescribe the predictives listed in Projecting potential cost savings from improved accuracy. and explain why it is important, and how external factors listed in Quality of data; employee training. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Payment Accuracy Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_KPI_Spending_Analysis_by_Category:
    "Tell me what a Spending Analysis by Category report should be, expand on the information from Tracks spending across categories to identify trends and savings.\n\nDescribe the predictives listed in Identifying potential savings opportunities based on patterns. and explain why it is important, and how external factors listed in Market conditions affecting pricing changes. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Spending Analysis by Category\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Payable_KPI_Supplier_Payment_Terms:
    "Tell me what a Supplier Payment Terms report should be, expand on the information from Analyzes agreed payment terms with suppliers and tracks compliance.\n\nDescribe the predictives listed in Future negotiation strategies based on compliance history. and explain why it is important, and how external factors listed in Supplier negotiation tactics; economic conditions. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Supplier Payment Terms\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Receivable_Analytics_Aging_of_Receivables:
    "Tell me what a Aging of Receivables report should be, expand on the information from Breakdown of outstanding receivables by age to identify collection issues.\n\nDescribe the predictives listed in Projecting cash inflows based on aging trends. and explain why it is important, and how external factors listed in Customer payment behavior; dynamics in industry credit practices. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Aging of Receivables\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Receivable_Analytics_Cash_Conversion_Cycle_CCC:
    "Tell me what a Cash Conversion Cycle (CCC) report should be, expand on the information from Analyzes time taken to convert resource investments into cash flows.\n\nDescribe the predictives listed in Assisting in cash flow management predictions. and explain why it is important, and how external factors listed in Supplier terms; sales cycle length. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Cash Conversion Cycle (CCC)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Receivable_Analytics_Collection_Effectiveness_Index:
    "Tell me what a Collection Effectiveness Index report should be, expand on the information from Evaluates the effectiveness of the collections process.\n\nDescribe the predictives listed in Forecasting future effectiveness based on historical collection performance. and explain why it is important, and how external factors listed in Market conditions; economic factors. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Collection Effectiveness Index\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Receivable_Analytics_Credit_Risk_Analysis:
    "Tell me what a Credit Risk Analysis report should be, expand on the information from Assesses risk associated with extending credit to customers.\n\nDescribe the predictives listed in Guiding credit policies based on past behavior of customers. and explain why it is important, and how external factors listed in Industry trends; economic cycles. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Credit Risk Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Receivable_Analytics_Customer_Payment_Trends:
    "Tell me what a Customer Payment Trends report should be, expand on the information from Analyzes payment behaviors and patterns of customers.\n\nDescribe the predictives listed in Forecasting future collections based on historical payment behavior. and explain why it is important, and how external factors listed in Changes in customer payment terms; economic stability. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Customer Payment Trends\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Receivable_Analytics_Dispute_Resolution_Time:
    "Tell me what a Dispute Resolution Time report should be, expand on the information from Tracks the time taken to resolve billing disputes with customers.\n\nDescribe the predictives listed in Predicting potential delays in cash inflows based on resolution times. and explain why it is important, and how external factors listed in Quality of invoicing process; customer relations. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Dispute Resolution Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Receivable_KPI_Average_Days_Delinquent_ADD:
    "Tell me what a Average Days Delinquent (ADD) report should be, expand on the information from Average number of days payments are past due.\n\nDescribe the predictives listed in Predicting future cash flow issues based on trends in delinquency. and explain why it is important, and how external factors listed in Changes in customer financial health; market conditions. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Average Days Delinquent (ADD)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Receivable_KPI_Bad_Debt_to_Sales_Ratio:
    "Tell me what a Bad Debt to Sales Ratio report should be, expand on the information from Proportion of account receivables written off as bad debt.\n\nDescribe the predictives listed in Forecasting potential future bad debt based on trends. and explain why it is important, and how external factors listed in Economic downturns; customer creditworthiness. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Bad Debt to Sales Ratio\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Receivable_KPI_Days_Sales_Outstanding_DSO:
    "Tell me what a Days Sales Outstanding (DSO) report should be, expand on the information from Measures average days to collect payment after sales.\n\nDescribe the predictives listed in Forecasting collection timeline based on historical data. and explain why it is important, and how external factors listed in Customer payment behaviors; economic conditions. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Days Sales Outstanding (DSO)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Receivable_KPI_Invoice_Dispute_Rate:
    "Tell me what a Invoice Dispute Rate report should be, expand on the information from Percentage of invoices disputed by customers.\n\nDescribe the predictives listed in Identifying potential issues in the invoicing process. and explain why it is important, and how external factors listed in Customer satisfaction and relations; clarity of invoices. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Invoice Dispute Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Accounts_Receivable_KPI_Receivables_Turnover_Ratio:
    "Tell me what a Receivables Turnover Ratio report should be, expand on the information from Measures effectiveness of using receivables for generating sales.\n\nDescribe the predictives listed in Forewarning of potential cash flow issues due to low turnover. and explain why it is important, and how external factors listed in Economic conditions; sales strategies. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Receivables Turnover Ratio\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Asset_Management_Analytics_Asset_Condition_Assessment:
    "Tell me what a Asset Condition Assessment report should be, expand on the information from Evaluates the current condition of assets to inform maintenance decisions.\n\nDescribe the predictives listed in Forecasting potential maintenance needs based on asset conditions. and explain why it is important, and how external factors listed in Aging assets; usage patterns. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Asset Condition Assessment\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Asset_Management_Analytics_Compliance_and_Risk_Management:
    "Tell me what a Compliance and Risk Management report should be, expand on the information from Tracks adherence to regulations and identifies risks associated with asset management.\n\nDescribe the predictives listed in Improving risk management strategies based on compliance results. and explain why it is important, and how external factors listed in Regulatory changes; market conditions. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Compliance and Risk Management\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Asset_Management_Analytics_Depreciation_Tracking:
    "Tell me what a Depreciation Tracking report should be, expand on the information from Monitors the depreciation of assets over time.\n\nDescribe the predictives listed in Forecasting asset value changes over its lifecycle. and explain why it is important, and how external factors listed in Economic factors; changes in asset utilization. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Depreciation Tracking\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Asset_Management_Analytics_Maintenance_Frequency_Analysis:
    "Tell me what a Maintenance Frequency Analysis report should be, expand on the information from Analyzes the frequency of asset maintenance activities.\n\nDescribe the predictives listed in Improving maintenance scheduling based on historical data. and explain why it is important, and how external factors listed in Operational demands; asset performance. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Maintenance Frequency Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Asset_Management_Analytics_Replacement_Cost_Analysis:
    "Tell me what a Replacement Cost Analysis report should be, expand on the information from Calculates the cost to replace an asset at current market rates.\n\nDescribe the predictives listed in Guiding budgeting for future asset replacements based on market trends. and explain why it is important, and how external factors listed in Supply chain factors; market volatility. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Replacement Cost Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Asset_Management_KPI_Asset_Age:
    "Tell me what a Asset Age report should be, expand on the information from Tracks the average age of assets to assess potential obsolescence.\n\nDescribe the predictives listed in Forecasting replacement or maintenance schedules. and explain why it is important, and how external factors listed in Technological advancements; changes in industry standards. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Asset Age\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Asset_Management_KPI_Asset_Disposal_Rate:
    "Tell me what a Asset Disposal Rate report should be, expand on the information from Measures the efficiency of disposing of assets that have reached end-of-life.\n\nDescribe the predictives listed in Forecasting disposal needs and asset replacement cycles. and explain why it is important, and how external factors listed in Market demand for used assets; regulatory changes. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Asset Disposal Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Asset_Management_KPI_Asset_Utilization_Rate:
    "Tell me what a Asset Utilization Rate report should be, expand on the information from Measures efficiency of utilizing assets to generate revenue.\n\nDescribe the predictives listed in Predicting future revenue generation capabilities based on asset use. and explain why it is important, and how external factors listed in Operational efficiency; investment in new technology. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Asset Utilization Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Asset_Management_KPI_Maintenance_Cost_as_a_Percentage_of_Asset_Value:
    "Tell me what a Maintenance Cost as a Percentage of Asset Value report should be, expand on the information from Assesses costs associated with maintaining assets.\n\nDescribe the predictives listed in Identifying areas for cost control in maintenance budgeting. and explain why it is important, and how external factors listed in Age of assets; maintenance practices. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Maintenance Cost as a Percentage of Asset Value\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Asset_Management_KPI_Return_on_Assets_ROA:
    "Tell me what a Return on Assets (ROA) report should be, expand on the information from Measures profitability in relation to total assets.\n\nDescribe the predictives listed in Forecasting profitability trends based on asset utilization. and explain why it is important, and how external factors listed in Market conditions; management efficiency. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Return on Assets (ROA)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Cash_Bank_Management_Analytics_Bank_Fees_Analysis:
    "Tell me what a Bank Fees Analysis report should be, expand on the information from Analyzes fees incurred from banking transactions to identify cost-saving opportunities.\n\nDescribe the predictives listed in Evaluating banking agreements for potential renegotiation opportunities. and explain why it is important, and how external factors listed in Changes in banking policies; volume of banking transactions. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Bank Fees Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Cash_Bank_Management_Analytics_Cash_Conversion_Cycle_CCC:
    "Tell me what a Cash Conversion Cycle (CCC) report should be, expand on the information from Analyzes the time taken to convert resource investments into cash flows.\n\nDescribe the predictives listed in Predicting cash flow peaks based on operational efficiency changes. and explain why it is important, and how external factors listed in Supplier and customer payment terms; market demand. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Cash Conversion Cycle (CCC)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Cash_Bank_Management_Analytics_Cash_Flow_Variance_Analysis:
    "Tell me what a Cash Flow Variance Analysis report should be, expand on the information from Compares projected vs actual cash flows to assess discrepancies.\n\nDescribe the predictives listed in Adjusting financial strategies based on cash flow performance. and explain why it is important, and how external factors listed in Economic conditions; operational performance. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Cash Flow Variance Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Cash_Bank_Management_Analytics_Fraud_Detection_and_Prevention:
    "Tell me what a Fraud Detection and Prevention report should be, expand on the information from Monitors and analyzes data to identify and prevent fraudulent activities.\n\nDescribe the predictives listed in Improving security measures based on past fraud incidents. and explain why it is important, and how external factors listed in Regulatory changes; economic environment. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Fraud Detection and Prevention\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Cash_Bank_Management_Analytics_Investment_Income:
    "Tell me what a Investment Income report should be, expand on the information from Measures the returns generated from the company's short-term investments.\n\nDescribe the predictives listed in Forecasting future investment opportunities based on historical performance. and explain why it is important, and how external factors listed in Interest rate fluctuations; market conditions. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Investment Income\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Cash_Bank_Management_KPI_Bank_Reconciliation_Time:
    "Tell me what a Bank Reconciliation Time report should be, expand on the information from Tracks the time taken to complete bank reconciliations.\n\nDescribe the predictives listed in Identifying process efficiencies to streamline cash management. and explain why it is important, and how external factors listed in Volume of transactions; complexity of accounts. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Bank Reconciliation Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Cash_Bank_Management_KPI_Cash_Flow_Forecasting_Accuracy:
    "Tell me what a Cash Flow Forecasting Accuracy report should be, expand on the information from Measures the precision of cash flow predictions compared to actual cash flows.\n\nDescribe the predictives listed in Improving forecasting methodologies based on past prediction accuracy. and explain why it is important, and how external factors listed in Market trends; changes in business operations. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Cash Flow Forecasting Accuracy\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Cash_Bank_Management_KPI_Days_Cash_on_Hand:
    "Tell me what a Days Cash on Hand report should be, expand on the information from Indicates the number of days the company can pay its expenses with current cash.\n\nDescribe the predictives listed in Assessing cash reserves to cover operational expenses in the short term. and explain why it is important, and how external factors listed in Changes in operational costs; revenue fluctuations. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Days Cash on Hand\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Cash_Bank_Management_KPI_Interest_Income_Expense_Analysis:
    "Tell me what a Interest Income/Expense Analysis report should be, expand on the information from Assesses the income generated from interest or costs incurred from interest on debts.\n\nDescribe the predictives listed in Forecasting future cash flow from interest on investments and debts. and explain why it is important, and how external factors listed in Interest rates; changes in market investment opportunities. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Interest Income/Expense Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Cash_Bank_Management_KPI_Liquidity_Ratio:
    "Tell me what a Liquidity Ratio report should be, expand on the information from Evaluates the company's ability to meet short-term liabilities with liquid assets.\n\nDescribe the predictives listed in Forecasting cash flow requirements based on liquidity levels. and explain why it is important, and how external factors listed in Economic conditions; market volatility. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Liquidity Ratio\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_General_Ledger_Analytics_Data_Integrity:
    "Tell me what a Data Integrity report should be, expand on the information from Assesses the accuracy and completeness of data entered.\n\nDescribe the predictives listed in Improving data entry processes based on integrity checks. and explain why it is important, and how external factors listed in Quality of source data; training practices. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Data Integrity\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_General_Ledger_Analytics_Intercompany_Reconciliation_Time:
    "Tell me what a Intercompany Reconciliation Time report should be, expand on the information from Measures the time required to reconcile intercompany transactions.\n\nDescribe the predictives listed in Identifying efficiencies in intercompany accounting processes. and explain why it is important, and how external factors listed in Volume of transactions; intercompany policies. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Intercompany Reconciliation Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_General_Ledger_Analytics_Internal_Audit_Findings:
    "Tell me what a Internal Audit Findings report should be, expand on the information from Tracks the number and significance of issues identified during internal audits.\n\nDescribe the predictives listed in Identifying areas of improvement based on audit outcomes. and explain why it is important, and how external factors listed in Compliance requirements; operational risks. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Internal Audit Findings\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_General_Ledger_Analytics_Regulatory_Compliance:
    "Tell me what a Regulatory Compliance report should be, expand on the information from Monitors adherence to financial regulations and standards.\n\nDescribe the predictives listed in Forecasting compliance risks based on historical data. and explain why it is important, and how external factors listed in Changes in regulatory requirements; industry standards. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Regulatory Compliance\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_General_Ledger_Analytics_Variance_Analysis:
    "Tell me what a Variance Analysis report should be, expand on the information from Compares expected versus actual figures to identify anomalies.\n\nDescribe the predictives listed in Guiding financial control measures based on variance trends. and explain why it is important, and how external factors listed in Budgets; market conditions. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Variance Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_General_Ledger_KPI_Accuracy_of_Financial_Statements:
    "Tell me what a Accuracy of Financial Statements report should be, expand on the information from Evaluates the correctness of reported financial data.\n\nDescribe the predictives listed in Identifying trends in reporting errors for future prevention. and explain why it is important, and how external factors listed in Data entry practices; training quality. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Accuracy of Financial Statements\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_General_Ledger_KPI_Balance_Sheet_Reconciliation_Time:
    "Tell me what a Balance Sheet Reconciliation Time report should be, expand on the information from Tracks the time taken to reconcile balance sheet accounts.\n\nDescribe the predictives listed in Forecasting efficiencies in reconciliation processes. and explain why it is important, and how external factors listed in Volume of transactions; information availability. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Balance Sheet Reconciliation Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_General_Ledger_KPI_Chart_of_Accounts_Utilization:
    "Tell me what a Chart of Accounts Utilization report should be, expand on the information from Analyzes how effectively the chart of accounts is used.\n\nDescribe the predictives listed in Improving chart structure and reducing redundancy. and explain why it is important, and how external factors listed in Business activities; changes in accounting standards. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Chart of Accounts Utilization\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_General_Ledger_KPI_Financial_Close_Time:
    "Tell me what a Financial Close Time report should be, expand on the information from Measures the time taken to close the books at the end of a financial period.\n\nDescribe the predictives listed in Assessing efficiency of the closing process for future periods. and explain why it is important, and how external factors listed in Staff efficiency; transaction volume. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Financial Close Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_General_Ledger_KPI_Journal_Entry_Error_Rate:
    "Tell me what a Journal Entry Error Rate report should be, expand on the information from Calculates the percentage of incorrect journal entries that require adjustment.\n\nDescribe the predictives listed in Monitoring accuracy for continuous improvement in entry processes. and explain why it is important, and how external factors listed in Staff training; complexity of transactions. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Journal Entry Error Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Inventory_Management_Analytics_Demand_Variability_Analysis:
    "Tell me what a Demand Variability Analysis report should be, expand on the information from Tracks fluctuations in consumer demand over time.\n\nDescribe the predictives listed in Guiding inventory adjustment strategies based on demand patterns. and explain why it is important, and how external factors listed in Sales promotions; economic cycles; competitor actions. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Demand Variability Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Inventory_Management_Analytics_Inventory_Valuation:
    "Tell me what a Inventory Valuation report should be, expand on the information from Assessing the value of all stock based on accounting methods.\n\nDescribe the predictives listed in Forecasting financial performance through correct inventory valuations. and explain why it is important, and how external factors listed in Adoption of accounting standards; market prices. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Inventory Valuation\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Inventory_Management_Analytics_Reorder_Point_Analysis:
    "Tell me what a Reorder Point Analysis report should be, expand on the information from Calculates minimum stock level before reordering.\n\nDescribe the predictives listed in Optimizing reorder levels to avoid stockouts. and explain why it is important, and how external factors listed in Supplier reliability; fluctuations in demand and lead times. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Reorder Point Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Inventory_Management_Analytics_Sales_Forecasting:
    "Tell me what a Sales Forecasting report should be, expand on the information from Predicts future product sales based on historical data and trends.\n\nDescribe the predictives listed in Guiding production and purchasing decisions. and explain why it is important, and how external factors listed in Market trends; economic conditions; promotional activities. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Sales Forecasting\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Inventory_Management_Analytics_Stock_Aging_Analysis:
    "Tell me what a Stock Aging Analysis report should be, expand on the information from Breakdown of inventory age to identify slow-moving items.\n\nDescribe the predictives listed in Predicting obsolescence and future markdown needs. and explain why it is important, and how external factors listed in Changes in consumer preferences; technological advancements. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Stock Aging Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Inventory_Management_KPI_Carrying_Cost_of_Inventory:
    "Tell me what a Carrying Cost of Inventory report should be, expand on the information from Total cost of storing and managing inventory.\n\nDescribe the predictives listed in Forecasting the financial impact of holding excess inventory. and explain why it is important, and how external factors listed in Storage costs; insurance rates; inventory losses. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Carrying Cost of Inventory\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Inventory_Management_KPI_Days_Inventory_Outstanding_DIO:
    "Tell me what a Days Inventory Outstanding (DIO) report should be, expand on the information from Average number of days inventory is held before being sold.\n\nDescribe the predictives listed in Identifying potential excess inventory issues. and explain why it is important, and how external factors listed in Market demand; lead times for suppliers. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Days Inventory Outstanding (DIO)\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Inventory_Management_KPI_Inventory_Turnover_Ratio:
    "Tell me what a Inventory Turnover Ratio report should be, expand on the information from Measures how often inventory is sold and replaced.\n\nDescribe the predictives listed in Forecasting future inventory needs based on sales trends. and explain why it is important, and how external factors listed in Seasonal demand fluctuations; supplier reliability. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Inventory Turnover Ratio\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Inventory_Management_KPI_Order_Accuracy_Rate:
    "Tell me what a Order Accuracy Rate report should be, expand on the information from Percentage of orders delivered correctly.\n\nDescribe the predictives listed in Identifying trends for future logistic improvements. and explain why it is important, and how external factors listed in Quality of order fulfillment processes; supplier reliability. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Order Accuracy Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Inventory_Management_KPI_Stockout_Rate:
    "Tell me what a Stockout Rate report should be, expand on the information from Frequency of inventory being depleted.\n\nDescribe the predictives listed in Predicting future stock levels based on historical stockout data. and explain why it is important, and how external factors listed in Supplier delays; changes in consumer demand. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Stockout Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sales_Management_Analytics_Customer_Segmentation_Analysis:
    "Tell me what a Customer Segmentation Analysis report should be, expand on the information from Analyzes customer data to categorize them into distinct segments.\n\nDescribe the predictives listed in Guiding targeted marketing and sales strategies for specific customer segments. and explain why it is important, and how external factors listed in Demographics; buying behaviors. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Customer Segmentation Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sales_Management_Analytics_Lead_Source_Analysis:
    "Tell me what a Lead Source Analysis report should be, expand on the information from Evaluates which lead sources contribute most to sales.\n\nDescribe the predictives listed in Evaluating marketing effectiveness and guiding resource allocation. and explain why it is important, and how external factors listed in Marketing campaign effectiveness; changes in lead generation methods. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Lead Source Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sales_Management_Analytics_Product_Performance_Analysis:
    "Tell me what a Product Performance Analysis report should be, expand on the information from Tracks sales performance by product to identify bestsellers and underperformers.\n\nDescribe the predictives listed in Forecasting future production needs based on product trends. and explain why it is important, and how external factors listed in Market demand; seasonal influences. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Product Performance Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sales_Management_Analytics_Revenue_Forecasting:
    "Tell me what a Revenue Forecasting report should be, expand on the information from Predicts future sales based on historical data and trends.\n\nDescribe the predictives listed in Guiding budget considerations and business strategy. and explain why it is important, and how external factors listed in Market trends; economic forecasts. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Revenue Forecasting\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sales_Management_Analytics_Sales_Funnel_Analysis:
    "Tell me what a Sales Funnel Analysis report should be, expand on the information from Visualizes the stages prospects go through before becoming customers.\n\nDescribe the predictives listed in Identifying bottlenecks or drop-off points in the sales process. and explain why it is important, and how external factors listed in Changes in buyer behavior; market conditions. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Sales Funnel Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sales_Management_Analytics_Sales_Territory_Performance:
    "Tell me what a Sales Territory Performance report should be, expand on the information from Analyzes performance of sales by geographic or market segments.\n\nDescribe the predictives listed in Guiding resource allocation for future sales strategies. and explain why it is important, and how external factors listed in Changes in territory demographics; competition levels. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Sales Territory Performance\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sales_Management_KPI_Average_Deal_Size:
    "Tell me what a Average Deal Size report should be, expand on the information from Calculates average revenue generated per closed deal.\n\nDescribe the predictives listed in Forecasting future revenue based on deal size trends. and explain why it is important, and how external factors listed in Market demand; competitive pricing strategies. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Average Deal Size\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sales_Management_KPI_Conversion_Rate:
    "Tell me what a Conversion Rate report should be, expand on the information from Percentage of leads that convert into actual sales.\n\nDescribe the predictives listed in Evaluating the effectiveness of the sales process and identifying areas for improvement. and explain why it is important, and how external factors listed in Quality of leads; effectiveness of sales pitches. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Conversion Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sales_Management_KPI_Sales_Cycle_Length:
    "Tell me what a Sales Cycle Length report should be, expand on the information from Measures the average time taken to close a sale.\n\nDescribe the predictives listed in Predicting future sales forecasting based on historical sales cycle times. and explain why it is important, and how external factors listed in Sales process efficiency; customer decision-making speed. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Sales Cycle Length\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sales_Management_KPI_Sales_Growth_Rate:
    "Tell me what a Sales Growth Rate report should be, expand on the information from Measures the percentage increase in sales over a specific period.\n\nDescribe the predictives listed in Forecasting future sales based on growth trends. and explain why it is important, and how external factors listed in Market conditions; overall economic environment. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Sales Growth Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sales_Management_KPI_Sales_Target_Achievement:
    "Tell me what a Sales Target Achievement report should be, expand on the information from Compares actual sales to sales targets set for a period.\n\nDescribe the predictives listed in Assessing effectiveness of sales strategies based on target performance. and explain why it is important, and how external factors listed in Changes in market dynamics; sales team performance. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Sales Target Achievement\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sourcing_and_Procurement_Analytics_Contract_Compliance_Analysis:
    "Tell me what a Contract Compliance Analysis report should be, expand on the information from Tracks adherence to contract terms by suppliers.\n\nDescribe the predictives listed in Forecasting areas of improvement based on compliance results. and explain why it is important, and how external factors listed in Supplier negotiation tactics; changing regulations. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Contract Compliance Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sourcing_and_Procurement_Analytics_Risk_Assessment_Analysis:
    "Tell me what a Risk Assessment Analysis report should be, expand on the information from Evaluates risks associated with procurement decisions.\n\nDescribe the predictives listed in Improving supplier selection and risk mitigation strategies. and explain why it is important, and how external factors listed in Market volatility; geopolitical issues. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Risk Assessment Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sourcing_and_Procurement_Analytics_Spend_Analysis:
    "Tell me what a Spend Analysis report should be, expand on the information from Analyzes spending patterns to identify cost-saving opportunities.\n\nDescribe the predictives listed in Guiding budgeting and forecasting strategies based on spending history. and explain why it is important, and how external factors listed in Economic factors; pricing trends in the market. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Spend Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sourcing_and_Procurement_Analytics_Supplier_Performance_Analysis:
    "Tell me what a Supplier Performance Analysis report should be, expand on the information from Evaluates supplier reliability and compliance with contract terms.\n\nDescribe the predictives listed in Identifying reliable suppliers for negotiation leverage. and explain why it is important, and how external factors listed in Supplier market conditions; performance tracking. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Supplier Performance Analysis\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sourcing_and_Procurement_Analytics_Supplier_Relationship_Management:
    "Tell me what a Supplier Relationship Management report should be, expand on the information from Analyzes relationships and effectiveness with vendors.\n\nDescribe the predictives listed in Forecasting potential negotiation opportunities based on relationship strength. and explain why it is important, and how external factors listed in Supplier dynamics; long-term relationships. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Supplier Relationship Management\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sourcing_and_Procurement_KPI_Cost_Reduction_Percentage:
    "Tell me what a Cost Reduction Percentage report should be, expand on the information from Measures savings achieved compared to previous periods or quoted prices.\n\nDescribe the predictives listed in Evaluating future cost-saving strategies based on historical data. and explain why it is important, and how external factors listed in Market price fluctuations; supplier competition. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Cost Reduction Percentage\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sourcing_and_Procurement_KPI_Order_Accuracy_Rate:
    "Tell me what a Order Accuracy Rate report should be, expand on the information from Percentage of purchase orders delivered correctly.\n\nDescribe the predictives listed in Identifying trends for improving ordering processes. and explain why it is important, and how external factors listed in Quality of supplier operations; clarity in order specifications. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Order Accuracy Rate\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sourcing_and_Procurement_KPI_Purchase_Order_Cycle_Time:
    "Tell me what a Purchase Order Cycle Time report should be, expand on the information from Average time from creating to fulfilling a purchase order.\n\nDescribe the predictives listed in Forecasting potential delays in supply chain management. and explain why it is important, and how external factors listed in Supplier reliability; lead times. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Purchase Order Cycle Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sourcing_and_Procurement_KPI_Spend_Under_Management:
    "Tell me what a Spend Under Management report should be, expand on the information from Proportion of total spend managed by procurement.\n\nDescribe the predictives listed in Assessing effectiveness of procurement strategies. and explain why it is important, and how external factors listed in Organizational spending policies; market competition. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Spend Under Management\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
  Explain_KPI_Sourcing_and_Procurement_KPI_Supplier_Lead_Time:
    "Tell me what a Supplier Lead Time report should be, expand on the information from Average time taken by suppliers to deliver goods.\n\nDescribe the predictives listed in Evaluating need for strategic sourcing improvements. and explain why it is important, and how external factors listed in Supplier efficiency; market conditions. and any others affect this goal.\n\nPlease create a comprehensive HTML page. Format your entire response as a single complete HTML5 document. Return ONLY the HTML document, with no additional formatting or escape characters. Include the following:\n\n1. Proper HTML5 document structure (<html>, <head>, <body>)\n2. Embedded CSS in the head section\n3. Clean, properly formatted HTML without escape characters\n4. Responsive table formatting\n5. Bootstrap 5 for styling (include the CDN)\n\nRequired sections:\n- Summary\n- Key Information about \n   - Name Supplier Lead Time\n   - Recommended Predictives [Column D]\n   - External Influencing Factors [Column G]\n\nStyle the HTML output to be clean and professional using internal CSS.\n\nReturn ONLY the HTML document, with no additional formatting or escape characters.\n",
};
