import React, { useState } from "react";
import "./Login.css";
import { useMeMutation } from "../../api/auth.api";
import Toast from "../../components/toasts/Toast/Toast";
import { useAppDispatch } from "../../app/hooks";
import { removeToken, setToken } from "../../utils/token";
import { removeUrl, setUrl } from "../../utils/url";
import { setAuth } from "../../slices/auth.slice";

export const Login: React.FC = () => {
  const [inputToken, setInputToken] = useState("");
  const [inputUrl, setInputUrl] = useState("");
  const [me, { isLoading }] = useMeMutation();
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<"success" | "error" | "info">(
    "info"
  );
  const dispatch = useAppDispatch();

  const handleApiError = (error: any) => {
    if (error.status === 403) {
      setToastMessage(
        "Access denied. Please check your token and permissions or contact your administrator."
      );
      setToastType("error");
    } else if (error.status === 404) {
      setToastMessage(
        "Login failed. The server could not be found. Please verify your URL."
      );
      setToastType("error");
    } else {
      setToastMessage("An unexpected error occurred. Please try again later.");
      setToastType("error");
    }
  };

  const handleLogin = async () => {
    if (!inputUrl || !inputToken) {
      setToastMessage("Both fields are required.");
      setToastType("error");
      return;
    }

    try {
      setUrl(inputUrl.trim());
      setToken(inputToken.trim());

      const response = await me().unwrap();

      if (response.status === "success") {
        dispatch(setAuth({ username: response.username }));
        setToastMessage("Logged in successfully!");
        setToastType("success");
        window.location.reload();
      } else {
        throw new Error("Invalid login response.");
      }
    } catch (err: any) {
      if (err.data && err.status) {
        handleApiError(err);
      } else {
        setToastMessage("Login failed. Please try again later.");
        setToastType("error");
      }
      console.error("Login error:", err);
      removeToken();
      removeUrl();
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="login-title">Login</h2>
        <div className="login-form">
          <label htmlFor="url" className="login-label">
            Odoo DNA URL:
          </label>
          <input
            type="text"
            id="url"
            className="login-input"
            placeholder="Enter your Odoo DNA URL"
            value={inputUrl}
            onChange={(e) => setInputUrl(e.target.value)}
          />
          <label htmlFor="token" className="login-label">
            Odoo DNA Token:
          </label>
          <input
            type="text"
            id="token"
            className="login-input"
            placeholder="Enter your Odoo DNA Token"
            value={inputToken}
            onChange={(e) => setInputToken(e.target.value)}
          />
          <button
            className="login-button"
            onClick={handleLogin}
            disabled={isLoading}
          >
            {isLoading ? "Logging in..." : "Login"}
          </button>
        </div>
      </div>
      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          onClose={() => setToastMessage("")}
        />
      )}
    </div>
  );
};
