export const findDataRanges = (data: any[]) => {
  if (!data?.length) return { min: 0, max: 0 };

  const numericFields = Object.keys(data[0] || {}).filter((key) => {
    return (
      !key.toLowerCase().includes("id") && typeof data[0][key] === "number"
    );
  });

  let min = Infinity;
  let max = -Infinity;
  let minKey = "";
  let maxKey = "";

  numericFields.forEach((key) => {
    data.forEach((item) => {
      const value = Number(item[key]);
      if (value < min) {
        min = value;
        minKey = key;
      }
      if (value > max) {
        max = value;
        maxKey = key;
      }
    });
  });

  return {
    min,
    max,
    minKey,
    maxKey,
  };
};
