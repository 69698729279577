export const generalChartDataConverter = (chartD: any) => {
  return chartD?.map((item: any) => {
    let convertedItem = { ...item };

    if (item?.month && item?.year) {
      const monthYear = `${item.month}/${item.year}`;
      const { month, year, ...rest } = item;
      convertedItem = {
        month: monthYear,
        ...rest,
      };
    }

    if (item?.product_name?.en_US) {
      const productName = item.product_name.en_US;
      const { product_name, ...rest } = item;
      convertedItem = {
        product_name: productName,
        ...rest,
      };
    }

    if (item?.stage_name?.en_US) {
      const stageName = item.stage_name.en_US;
      const { stage_name, ...rest } = item;
      convertedItem = {
        stage_name: stageName,
        ...rest,
      };
    }

    return convertedItem;
  });
};

export const groupChartData = (
  chartD: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  if (
    !chartD ||
    !Array.isArray(chartD) ||
    !selectedIndex ||
    !selectedKeys?.length
  ) {
    return [];
  }

  const validData = chartD.filter((item) => item && typeof item === "object");

  if (!validData.length) {
    return [];
  }

  const groupedData = validData.reduce(
    (acc: Record<string, any>, item: any) => {
      const groupKey = item[selectedIndex];

      if (!groupKey) return acc;

      if (!acc[groupKey]) {
        acc[groupKey] = {
          [selectedIndex]: groupKey,
          ...item,
        };

        selectedKeys.forEach((key) => {
          acc[groupKey][key] = 0;
        });
      }

      selectedKeys.forEach((key) => {
        acc[groupKey][key] += Number(item[key]) || 0;
      });

      return acc;
    },
    {} as Record<string, any>
  );

  return Object.values(groupedData);
};
