import { prompts } from "./prompts";

const AUTH_TOKEN = "_b64c5d0a5dd13a90b1e7e9304637628c44416a93";
const COOKIE =
  "session_id=VXJ67mOaeLbV_8ORBN9K2ncLd6HIFBcxM6HGWtFVJ_7T4FJyLKMXV8XC1WFi9HCj_mDb6l8HROme7LIfPfhV";
const API_URL = "https://4sqcapital.odoo.com/v1/data/sale";

const flattenJSON = (data: any) => {
  return data?.map((item: any) => ({
    sale_order_id: item?.sale_order_id,
    sale_order_name: item?.sale_order_name,
    product_id: item?.product_id,
    product_name: item?.product_name?.en_US,
    product_quantity: item?.product_quantity,
    sale_status: item?.sale_status,
    line_total_amount: item?.line_total_amount,
    company_name: item?.company_name,
  }));
};

const findBestMatchingPrompt = async (kpiName: string, prompts: any) => {
  try {
    const formattedName = kpiName
      .replace(/[()[\]{}]/g, "")
      .replace(/[^a-zA-Z0-9_\s]/g, "")
      .trim()
      .replace(/\s+/g, "_")
      .toLowerCase();

    if (prompts[formattedName]) {
      return prompts[formattedName];
    }

    const promptNames = Object.keys(prompts);
    const matchingPrompt = promptNames.find((name) =>
      name.toLowerCase().includes(formattedName.toLowerCase())
    );

    return matchingPrompt ? prompts[matchingPrompt] : null;
  } catch (error) {
    console.error(`Error finding prompt for ${kpiName}:`, error);
    throw error;
  }
};

const fetchSalesData = async () => {
  try {
    const response = await fetch(API_URL, {
      method: "GET",
      headers: {
        Authorization: `${AUTH_TOKEN}`,
        Cookie: COOKIE,
      },
    });

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    const salesData = await response.json();

    if (!Array.isArray(salesData)) {
      throw new Error("Invalid JSON response received from API");
    }

    return salesData;
  } catch (error) {
    console.error("Error: Failed to fetch sales data from API", error);
    throw error;
  }
};

const generateCSVContent = (flattenedData: any[]) => {
  const csvHeader =
    '"sale_order_name","product_name","product_quantity","sale_status","line_total_amount","company_name"\n';
  const csvRows = flattenedData
    ?.map(
      (item: any) =>
        `"${item.sale_order_name}","${item.product_name}","${item.product_quantity}","${item.sale_status}","${item.line_total_amount}","${item.company_name}"`
    )
    .join("\n");

  return csvHeader + csvRows;
};

const createPromptTemplate = (
  dna: string,
  promptContent: string,
  csvContent: string,
  message?: string
) =>
  `Return JSON only analyzing ${dna}: {"name":"${dna}","summary":"","key_findings":[""],"data_visualizations":[{"name":"","type":"PieChart","data":[{"category":"draft","value":5}]},{"name":"","type":"BarChart","data":[{"category":"","current":0,"previous":0}]}],"detailed_analysis":{"description":"","recommendations":[""]}} Rules: 5+ data points, numbers only, focus on ${dna}, 3+ findings. Data: ${csvContent.slice(
    0,
    2000
  )} ${promptContent} ${
    message ? `Q: ${message}` : ""
  }, do not add any comments after JSON. In charts show only either bar, pie or line chart. Do not use any other chart type.`;

// const createPromptTemplate = (
//   promptContent: string,
//   csvContent: string,
//   message?: string
// ) => `
//   You are a React developer. Generate a response using only HTML and inline CSS (no external stylesheets or scripts).
//   Follow these rules:
//   1. Use semantic HTML5 elements
//   2. Include all styles inline using style={{}}
//   3. No external CSS or JavaScript references
//   4. No DOCTYPE, html, head, or body tags
//   5. Return only the content that would go inside a React component's render
//   6. Use tables for data presentation
//   7. Use consistent styling with:
//      - Font: Arial, sans-serif
//      - Colors: #f8f9fa for background, #333 for text
//      - Borders: #ddd
//      - Border radius: 8px
//      - Box shadow: 0 0 15px rgba(0, 0, 0, 0.1)
//   8. Return only summary, key findings, 2 charts and 1 table

//   ${promptContent}

//   ${message ? `DESCRIPTION: ${message}` : ""}

//   SALES DATA:
//   ${csvContent.slice(0, 2000)}
// `;

// const createPromptTemplate = (
//   promptContent: string,
//   csvContent: string,
//   message?: string
// ) => `
// You are a React developer. Generate a response using only HTML and inline CSS (no external stylesheets or scripts).
// Follow these rules:
// 1. Use inline styles with style={{}}
// 2. Include these chart components:
// 3. No external CSS or JavaScript references
// 4. No DOCTYPE, html, head, or body tags
// 5. Return only the content that would go inside a React component's render
// 6. Include charts taken from the Chart.js documentation using React.js:
// Example Line Chart:
// <Line
//   data={{
//     labels: ['Jan', 'Feb', 'Mar'],
//     datasets: [{
//       label: 'Sales',
//       data: [10, 20, 30],
//       borderColor: 'rgb(75, 192, 192)',
//       fill: false
//     }]
//   }}
//   options={{ responsive: true }}
// />

// Styling:
// - Font: Arial, sans-serif
// - Background: #f8f9fa
// - Text: #333
// - Borders: #ddd
// - Border radius: 8px
// - Box shadow: 0 0 15px rgba(0, 0, 0, 0.1)

// ${promptContent}
// ${message ? `DESCRIPTION: ${message}` : ""}
// SALES DATA: ${csvContent.slice(0, 2000)}
// `;

export const fetchDiaResponse = async (dna: string, message?: string) => {
  const salesData = await fetchSalesData();
  const flattenedData = await flattenJSON(salesData);
  const csvContent = generateCSVContent(flattenedData);

  const promptContent = await findBestMatchingPrompt(dna, prompts);

  const promptTemplate = createPromptTemplate(
    dna,
    promptContent,
    csvContent,
    message
  );
  // const jsonPayload = {
  //   model: MODEL,
  //   prompt: promptTemplate,
  //   stream: false,
  // };

  // return await sendRequestToOllama(jsonPayload);
  return promptTemplate;
};
