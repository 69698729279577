import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl } from "../utils/url";
import { getToken } from "../utils/token";

export const iosApi = createApi({
  reducerPath: "iosApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl() + "/ios",
    headers: {
      Authorization: `${getToken()}`,
    },
  }),
  endpoints: (build) => ({
    dnas: build.mutation<any, void>({
      query: () => ({
        url: "/",
        method: "GET",
      }),
    }),
    dna: build.mutation<any, string>({
      query: (dna) => ({
        url: `/${dna}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useDnasMutation, useDnaMutation } = iosApi;
