import React from "react";
import { Chart } from "react-google-charts";

interface GaugeChartProps {
  data: any;
}

export const GaugeChartGoogle: React.FC<GaugeChartProps> = ({ data }) => {
  if (!data || !Array.isArray(data) || data.length <= 1) {
    return null;
  }

  const options = {
    redFrom: 90,
    redTo: 100,
    yellowFrom: 75,
    yellowTo: 90,
    minorTicks: 5,
    animation: {
      duration: 1000,
      easing: "out",
    },
  };

  return (
    <Chart
      chartType="Gauge"
      width="100%"
      height="100%"
      data={data}
      options={options}
    />
  );
};
